import update from 'immutability-helper';
import { findIndex, uniqueId } from 'lodash-es';

// Actions
const CREATE_NOTIFICATION = 'core/notifications/CREATE_NOTIFICATION';
const REMOVE_NOTIFICATION = 'core/notifications/REMOVE_NOTIFICATION';

// Initial state
const initialState = [];

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return update(state, {
        $push: [
          {
            id: action.id,
            message: action.message,
            color: action.color,
            autoClose: action.autoClose,
          },
        ],
      });

    case REMOVE_NOTIFICATION: {
      const index = findIndex(state, { id: action.id });
      return update(state, { $splice: [[index, 1]] });
    }

    default:
      return state;
  }
};

// Action creators
const addNotification = (id, message, color, autoClose) => ({
  type: CREATE_NOTIFICATION,
  id,
  message,
  color,
  autoClose,
});

export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  id,
});

export const createNotification = (message, color, autoClose) => dispatch => {
  const id = uniqueId();
  dispatch(addNotification(id, message, color, autoClose));

  if (autoClose !== false) {
    setTimeout(() => dispatch(removeNotification(id)), autoClose);
  }
};
