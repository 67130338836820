import styled from 'styled-components';

const SearchInputStyled = styled.div`
  & > .search-input {
    padding: 12px 12px;
    height: 54px;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  & > .search-input::before {
    display: block;
    position: absolute;
    width: 20px;
    z-index: 3;
    height: 20px;
    top: 18px;
    left: 12px;
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADCElEQVRYR+3Wy0tUURzA8e9vdMIhp6CgwJ7QO9oVUVJkjz9ACmpVlvfKBJrObdUiyhYtTNCrKRTNWEqbCIrWvR/0gKJNYQ8KKgoiih5WkjP3F161EnPu8Ya16a6GOff8zuec+/udc4R//Mg/Hp9gQKplBmRLQUtA5yDMBMmi+gJ4iHAVjZ7GrnoWZjLDA1SFdFMlogdAYjmDK18Q3UV5sgURHQnk94BUwwREToCs6w92DdUzoFfIk0f+f1mdi0RWgm4AWd73np5DdRP2znemiKGA9MEiNHMdkRmoPkLzyqiovpkz4JHmZUS8DmAOqs+Q/GKsHa9MEIMBra2FFPTcBuahep9odBVlVW9NAtHeMpFM5hKwyM+N7ugSKiu7gvoOBqTcPQj7UJ6jLKUi+ToowKD2I+5kRG/5q4fUYtXsC+r/E5Cui6NjXiAyHomUUF59Oajzb9vbmleh3iWULrKxqSQSH3LF+QlINZUhegzlPHZyIPlCGUg3nvUTWGUrdk27IcA9jVCKsh07eTjcyP29Um4C4ZBfObZTaghofIjIXDxdSIXT+UeAowfn42U7/SqynXlmgHTjR5A4mdhYEokvfwToqB9LT7QL9BOWM25kgO5o3KR8cgL7yvkT6Gcsp9AQ4D7w6z+St4BtO3p/h3/Sbu+yP0B5gp2cbQZIjUoSnsJ2NhgCGjcj0gF6ActZG376QMo9h7AWVQvbaTMDHG8eR7f3EqEQZA1WzcVQiIGNCN4T94rYuPOrGaD3rXTTXtBa0Jd4sjjUVhzROyBTEN1NubM/aBLDH0Zwj/z8klCHkXKXWe+Xsro2MzKAvwq/HMfwGC+yxeg4lmy7v5H5jz7Fk2KTFRzZhSQiV+mJ9ZVo9Ot8lBXAeqC4b1ztBBmDMMsUkftK1uZWAXWBVzJ4A7hkYvUUfJ5ET+Q6wnQTRIhLqUwD+rdXvQG0Ev9wko2133587/aGKaaIYEBQFg3XbogYPUAvzAAxugADxOgDhiCwsZLpgS/3dwADiIxswnIafk2bvwcYJln/A74DattRMOo0SvIAAAAASUVORK5CYII=');
    background-size: 20px;
    background-repeat: no-repeat;
  }

  & > .search-input > input {
    width: 100%;
    font-size: 18px;
    border: none;
    line-height: 22px;
    padding: 5px 10px 5px 25px;
    height: 32px;
    position: relative;
  }

  & > .search-input > input:focus {
    outline: none;
  }

  & > .search-input ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

export default SearchInputStyled;
