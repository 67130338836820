import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import {
  Modal,
  ModalDialog,
  ModalTitle,
  ModalClose,
  ModalCloseIcon,
  ModalSubtitle,
} from '../../../core/components/styled';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadUsers } from '../../../users/ducks';
import { saveAccountProfile } from '../../ducks';
import { AccountProfileForm } from '../forms';

class AccountProfileModal extends PureComponent {
  onSubmit = accountProfile => {
    const { saveAccountProfile, loadUsers, location, closeModal } = this.props;
    const { page, limit, sortOrder, sortedBy, searchTerm } = getQueryParams(location.search);
    saveAccountProfile(accountProfile)
      .then(async () => {
        createSuccessNotification(`Profile updated succesfully`);
        if (location.pathname === '/users') {
          await loadUsers(page, limit, sortOrder, sortedBy, searchTerm);
        }
        closeModal();
      })
      .catch(() => createErrorNotification('Profile was not updated.Please try again'));
  };

  render() {
    const { email, closeModal } = this.props;

    return (
      <Modal>
        <ModalDialog size="medium" sizeTablet="medium" sizePhone="medium">
          <ModalClose onClick={closeModal}>
            <ModalCloseIcon />
          </ModalClose>
          <ModalTitle>Your Profile</ModalTitle>
          <ModalSubtitle>{email}</ModalSubtitle>

          <AccountProfileForm onSubmit={this.onSubmit} email={email} />
        </ModalDialog>
      </Modal>
    );
  }
}

AccountProfileModal.propTypes = {
  email: PropTypes.string.isRequired,
  saveAccountProfile: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapStateToProps = state => ({
  email: state.account.profile.accountProfile.email,
});

const mapDispatchToProps = { saveAccountProfile, loadUsers };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AccountProfileModal),
);
