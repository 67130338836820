import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ButtonSet, Button, Grid, GridColumn } from '../../../core/components/styled';
import { Input, RadioGroup } from '../../../core/components';
import { isRequired, isPhone } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { isPhoneNumberVerifiedSelector, refreshAuthToken } from '../../ducks/login';
import { sendVerificationCode, validatePhoneNr } from '../../ducks';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';

const phoneValidationOptions = [{ label: 'Text message', code: 'text' }, { label: 'Voice call', code: 'voice' }];
class AccountProfileForm extends PureComponent {
  state = {
    showVerificationCodeField: false,
    phoneValidationDetails: undefined,
  };

  sendVerificationCode = () => {
    const { phoneNumber, email, phoneValidationType, sendVerificationCode } = this.props;

    const params = {
      phoneNumber,
      sendVoiceMessage: phoneValidationType === 'voice',
      email,
      countryCode: '+1',
    };
    sendVerificationCode(params)
      .then(async result => {
        this.setState({
          phoneValidationDetails: result.data,
          showVerificationCodeField: true,
        });
        createSuccessNotification(`Validation code successfully sent!`);
      })
      .catch(() => createErrorNotification('Something went wrong! Please try again later!'));
  };

  validatePhoneNumber = () => {
    const { phoneValidationDetails } = this.state;
    const { validatePhoneNr, verificationCode, refreshAuthToken } = this.props;
    const params = {
      userId: phoneValidationDetails.userId,
      requestToken: phoneValidationDetails.resultToken,
      code: verificationCode,
    };
    validatePhoneNr(params)
      .then(async () => {
        createSuccessNotification(`Your phone number has been successfully confirmed!`);
        this.setState({
          showVerificationCodeField: false,
        });
        refreshAuthToken();
      })
      .catch(() =>
        createErrorNotification(
          'Something went wrong, we could not validate your phone number! Please try again later!',
        ),
      );
  };

  validationForm = () => {
    const { showVerificationCodeField } = this.state;
    return showVerificationCodeField ? (
      <>
        <GridColumn size="10/12" sizeTablet="12/12" sizePhone="12/12">
          <Field name="verificationCode" component={Input} label="Verification Code" />
        </GridColumn>
        <GridColumn size="10/12" sizeTablet="12/12" sizePhone="12/12">
          <ButtonSet>
            <Button type="button" color="primary" onClick={this.validatePhoneNumber}>
              Validate phone number
            </Button>
          </ButtonSet>
        </GridColumn>
      </>
    ) : (
      <>
        <GridColumn size="10/12" sizeTablet="12/12" sizePhone="12/12">
          <Field
            name="phoneValidationType"
            items={phoneValidationOptions}
            component={RadioGroup}
            label="Phone number verification"
          />
        </GridColumn>
        <GridColumn size="10/12" sizeTablet="12/12" sizePhone="12/12">
          <ButtonSet>
            <Button type="button" color="primary" onClick={this.sendVerificationCode}>
              Send verification code
            </Button>
          </ButtonSet>
        </GridColumn>
      </>
    );
  };

  render() {
    const { handleSubmit, isPhoneNumberVerified } = this.props;
    const { showVerificationCodeField } = this.state;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Grid multiLine centered>
          <GridColumn size="5/12" sizeTablet="6/12" sizePhone="12/12">
            <Field name="firstName" component={Input} label="First name" validate={[isRequired]} />
          </GridColumn>
          <GridColumn size="5/12" sizeTablet="6/12" sizePhone="12/12">
            <Field name="lastName" component={Input} label="Last name" validate={[isRequired]} />
          </GridColumn>

          <GridColumn size="10/12" sizeTablet="12/12" sizePhone="12/12">
            <Field
              name="phoneNumber"
              component={Input}
              label="Phone"
              validate={[isRequired, isPhone]}
              disabled={showVerificationCodeField}
            />
          </GridColumn>

          {!isPhoneNumberVerified ? this.validationForm() : null}

          <GridColumn>
            <ButtonSet>
              <Button color="primary">Update</Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

AccountProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  isPhoneNumberVerified: PropTypes.bool.isRequired,
  validatePhoneNr: PropTypes.func.isRequired,
  sendVerificationCode: PropTypes.func.isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  phoneValidationType: PropTypes.string,
  verificationCode: PropTypes.string,
};

AccountProfileForm.defaultProps = {
  phoneNumber: undefined,
  verificationCode: undefined,
  phoneValidationType: undefined,
};

const formSelector = formValueSelector('accountProfile');

const mapStateToProps = state => {
  const { firstName, lastName, phoneNumber } = state.account.profile.accountProfile;
  return {
    initialValues: {
      firstName,
      lastName,
      phoneNumber,
    },
    phoneNumber: formSelector(state, 'phoneNumber'),
    phoneValidationType: formSelector(state, 'phoneValidationType'),
    verificationCode: formSelector(state, 'verificationCode'),
    isPhoneNumberVerified: isPhoneNumberVerifiedSelector(state.account.login),
  };
};

const mapDispatchToProps = { sendVerificationCode, validatePhoneNr, refreshAuthToken };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'accountProfile',
    onSubmitFail: focusFirstInvalidField,
  })(AccountProfileForm),
);
