import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { map } from 'lodash-es';
import { ButtonSet, Button, Grid, GridColumn } from '../../../core/components/styled';
import { Input, Select } from '../../../core/components';
import { isRequired, isEmail, isPhone } from '../../../utils/services/validator';
import { PARTNER_SUPER_ADMIN, PARTNER_ADMIN } from '../../../account/constants';
import { RoleGuard, SuperAdminGuard } from '../../../account/components';
import { UserTypeSelect } from '..';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import userEditorFormInitialValuesSelector from '../../services/userEditorFormInitialValuesSelector';

class UserEditorForm extends PureComponent {
  render() {
    const { loggedInUserId, userId, handleSubmit, companies, companyId } = this.props;
    const companiesOptions = map(companies, ({ name, id }) => ({ value: id, label: name }));

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Grid multiLine centered>
          <RoleGuard roles={[PARTNER_SUPER_ADMIN, PARTNER_ADMIN]}>
            <GridColumn size="10/12" sizeTablet="10/12" sizePhone="12/12">
              <Field
                name="role"
                component={UserTypeSelect}
                label="User type"
                validate={[isRequired]}
                selectProps={{ disabled: loggedInUserId === userId }}
              />
            </GridColumn>
          </RoleGuard>
          <SuperAdminGuard>
            <GridColumn size="10/12" sizeTablet="10/12" sizePhone="12/12">
              <Field name="companyId" component={Select} options={companiesOptions} label={companyId && 'Company'}>
                <option value="">Add new company</option>
              </Field>
            </GridColumn>
            {!companyId && (
              <GridColumn size="10/12" sizeTablet="10/12" sizePhone="12/12">
                <Field name="companyName" component={Input} label="Company name" type="text" validate={[isRequired]} />
              </GridColumn>
            )}
          </SuperAdminGuard>
          <GridColumn size="5/12" sizeTablet="5/12" sizePhone="12/12">
            <Field name="firstName" component={Input} label="First name" type="text" validate={[isRequired]} />
          </GridColumn>
          <GridColumn size="5/12" sizeTablet="5/12" sizePhone="12/12">
            <Field name="lastName" component={Input} label="Last Name" type="text" validate={[isRequired]} />
          </GridColumn>

          <GridColumn size="10/12" sizeTablet="10/12" sizePhone="12/12">
            <Field
              name="email"
              component={Input}
              label="Email"
              type="email"
              validate={[isRequired, isEmail]}
              disabled={!!userId}
            />
          </GridColumn>

          <GridColumn size="10/12" sizeTablet="10/12" sizePhone="12/12">
            <Field
              name="phoneNumber"
              component={Input}
              label="Phone number"
              type="phone"
              validate={[isRequired, isPhone]}
            />
          </GridColumn>
          <GridColumn>
            <ButtonSet>
              <Button color="primary">Save user</Button>
            </ButtonSet>
          </GridColumn>
        </Grid>
      </form>
    );
  }
}

UserEditorForm.propTypes = {
  loggedInUserId: PropTypes.string.isRequired,
  userId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  companies: PropTypes.array,
  companyId: PropTypes.string,
};

UserEditorForm.defaultProps = {
  userId: undefined,
  companies: undefined,
  companyId: undefined,
};

const formSelector = formValueSelector('userEditor');

const mapStateToProps = state => ({
  loggedInUserId: state.account.login.user.userId,
  companies: state.users.companies.companies,
  companyId: formSelector(state, 'companyId'),
  initialValues: userEditorFormInitialValuesSelector(state.users.user, state.account.login),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'userEditor',
    onSubmitFail: focusFirstInvalidField,
  })(UserEditorForm),
);
