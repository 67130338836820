import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { createSelector } from 'reselect';
import { map, identity } from 'lodash-es';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import {
  Panel,
  Button,
  ButtonLink,
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
  PanelSectionActions,
  MapContainer,
  Text,
  Message,
  FormError,
} from '../../../core/components/styled';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import ClusterMap from '../../../core/components/ClusterMap';

import { phoneNumber } from '../../../utils/services/formatter';
import { PARTNER_USER, PARTNER_MANAGER, PARTNER_ADMIN } from '../../../account/constants';
import { RoleGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { isPhoneNumberVerifiedSelector } from '../../../account/ducks/login';
import { sendShoppingCart, signWithCustomer, locationsSelector, qualifiedServiceCountSelector } from '../../ducks';
import { CustomerEditorModal } from '../modals';
import ServiceSummary from '../ServiceSummary';
import {
  SummaryResponsiveInfoPanel,
  SummaryInfoWrapper,
  SummaryPercentage,
  SummaryAction,
  SummaryTitle,
} from '../styled';
import { getUser } from '../../../account/services/auth';

const addressesSelector = createSelector(
  locations => map(locations, 'address'),
  identity,
);

class SummaryPage extends PureComponent {
  state = { isCustomerModalOpen: false };

  openCustomerModal = () => {
    this.setState({ isCustomerModalOpen: true });
  };

  closeCustomerModal = () => {
    this.setState({ isCustomerModalOpen: false });
  };

  sendShoppingCart = () => {
    const { quoteId, sendShoppingCart, push } = this.props;

    sendShoppingCart(quoteId)
      .then(() => {
        createSuccessNotification('The shopping cart was sent to the customer.');
        push('/opportunities');
      })
      .catch(() => {
        createErrorNotification('There was an error creating the cart.', false);
      });
  };

  signWithCustomer = () => {
    const { quoteId, signWithCustomer } = this.props;

    signWithCustomer(quoteId)
      .then(url => {
        window.location.href = url;
      })
      .catch(() => {
        createErrorNotification('There was an error creating the cart.', false);
      });
  };

  formatSavingsPercent = savingsPercent =>
    `${parseFloat(savingsPercent * 100)
      .toFixed(2)
      .replace(/[.,]00$/, '')}%`;

  render() {
    const { isCustomerModalOpen } = this.state;
    const {
      quoteId,
      customerEmail,
      customerPhone,
      locations,
      serviceCount,
      isSaving,
      isCreatingShoppingCart,
      isPartnerSuperAdmin,
      savingsPercent,
      isPhoneNumberVerified,
    } = this.props;
    const addresses = addressesSelector(locations);
    const user = getUser();
    return (
      <>
        <PageHeader>
          <PageTitle>Summary</PageTitle>

          <RoleGuard roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}>
            <PageActions alignPhone="center">
              <Button
                line
                color="primary"
                margin="no small no no"
                disabled={!serviceCount || isSaving || isCreatingShoppingCart || !customerEmail || !customerPhone}
                onClick={this.sendShoppingCart}
              >
                Send to customer
              </Button>

              <Button
                color="primary"
                disabled={
                  !serviceCount ||
                  isSaving ||
                  isCreatingShoppingCart ||
                  !customerEmail ||
                  !customerPhone ||
                  !isPhoneNumberVerified
                }
                onClick={this.signWithCustomer}
              >
                Sign with customer
              </Button>
            </PageActions>
            {!isPhoneNumberVerified && (
              <FormError alignRight>
                Please confirm your phone number in the account profile section in order to proceed.
              </FormError>
            )}
          </RoleGuard>
        </PageHeader>

        <Panel isLoading={isSaving || isCreatingShoppingCart}>
          <PanelSection>
            <MapContainer>
              <ClusterMap
                styled
                options={{
                  minZoom: 2,
                  maxZoom: 15,
                  mapTypeControlOptions: {
                    style: 3,
                    position: 1,
                    mapTypeIds: ['roadmap', 'satelite', 'hybrid'],
                  },
                }}
                markersList={addresses.map(address => ({
                  id: address.zip,
                  lat: address.latitude,
                  lng: address.longitude,
                  zip: address.zip,
                }))}
              />
            </MapContainer>
          </PanelSection>

          <PanelSection padding="medium medium large">
            <SummaryResponsiveInfoPanel margin="no no small">
              <SummaryInfoWrapper>
                <div>
                  <SummaryTitle>Contact details</SummaryTitle>
                  <Text block margin="no no xSmall" color="grayDarker">
                    {customerEmail || 'No email provided'}
                  </Text>
                  <Text block color="grayDarker">
                    {phoneNumber(customerPhone) || 'No phone number provided'}
                  </Text>
                </div>

                {!isPartnerSuperAdmin && (
                  <SummaryAction>
                    <Button text color="primary" noPaddingMedia onClick={this.openCustomerModal}>
                      Edit contact details
                    </Button>
                  </SummaryAction>
                )}
              </SummaryInfoWrapper>
              {!user.hasIncumbentRebateProgram && (
                <SummaryInfoWrapper marginBottom>
                  <SummaryTitle noMobileMargin>Customer Savings</SummaryTitle>
                  <SummaryPercentage>{this.formatSavingsPercent(savingsPercent)}</SummaryPercentage>
                </SummaryInfoWrapper>
              )}
            </SummaryResponsiveInfoPanel>
          </PanelSection>

          <PanelSection padding="no medium small">
            <PanelSectionHeader margin="no no small">
              <PanelSectionTitle>Services</PanelSectionTitle>
              {!isPartnerSuperAdmin && (
                <PanelSectionActions>
                  <ButtonLink text color="primary" to={`/quote/${quoteId}/choose-provider`}>
                    Add location
                  </ButtonLink>
                </PanelSectionActions>
              )}
            </PanelSectionHeader>

            {!locations.length && <Message>No locations were added to this quote.</Message>}
            {!!locations.length && <ServiceSummary quoteId={quoteId} showPrice />}
          </PanelSection>
        </Panel>

        {isCustomerModalOpen && <CustomerEditorModal quoteId={quoteId} closeModal={this.closeCustomerModal} />}
      </>
    );
  }
}

SummaryPage.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isCreatingShoppingCart: PropTypes.bool.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  quoteId: PropTypes.string,
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  savingsPercent: PropTypes.number,
  locations: PropTypes.array.isRequired,
  serviceCount: PropTypes.number.isRequired,
  sendShoppingCart: PropTypes.func.isRequired,
  signWithCustomer: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  isPhoneNumberVerified: PropTypes.bool.isRequired,
};

SummaryPage.defaultProps = {
  quoteId: undefined,
  customerEmail: undefined,
  customerPhone: undefined,
  savingsPercent: undefined,
};

const mapStateToProps = state => {
  const locationsFilter = { mustHaveAddress: true };
  const { quote } = state.quote;

  return {
    quoteId: quote.id,
    isSaving: quote.isSaving,
    isCreatingShoppingCart: quote.isCreatingShoppingCart,
    customerEmail: quote.customerEmail,
    customerPhone: quote.customerPhone,
    savingsPercent: quote.savingsPercent,
    locations: locationsSelector(quote, locationsFilter),
    serviceCount: qualifiedServiceCountSelector(quote),
    isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
    isPhoneNumberVerified: isPhoneNumberVerifiedSelector(state.account.login),
  };
};

const mapDispatchToProps = {
  sendShoppingCart,
  signWithCustomer,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SummaryPage),
);
