import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PanelSectionLoading } from '../../../core/components/styled';
import { Resolver } from '../../../core/components';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { CONTACTED } from '../../../quote/constants';
import { loadOpportunities } from '../../ducks';
import ContactedOpportuniesSection from './ContactedOpportuniesSection';

class ContactedOpportunitiesSectionResolver extends PureComponent {
  resolve = () => {
    const {
      loadOpportunities,
      location: { search },
    } = this.props;
    const { page, limit, sortedBy, sortOrder } = getQueryParams(search);
    return loadOpportunities(CONTACTED, page, limit, sortedBy, sortOrder);
  };

  render() {
    return (
      <>
        <DocumentTitle>Contacted Opportunities</DocumentTitle>
        <Resolver
          successComponent={ContactedOpportuniesSection}
          loadingComponent={PanelSectionLoading}
          resolve={this.resolve}
        />
      </>
    );
  }
}

ContactedOpportunitiesSectionResolver.propTypes = {
  loadOpportunities: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
};

const mapDispatchToProps = { loadOpportunities };

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(ContactedOpportunitiesSectionResolver),
);
