import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { toNumberIfNotEmptyString } from '../../../utils/services/normalizer';
import { ButtonSet, Button } from '../../../core/components/styled';
import { Input, DatePicker } from '../../../core/components';
import validate from './validators/customerEditorValidator';
import { isAvailablePhone } from './validators/customerEditorAsyncValidator';
import { getUser } from '../../../account/services/auth';

const disabledDates = [{ before: new Date() }, { daysOfWeek: [0, 6] }];

const CustomerEditorForm = ({ handleSubmit }) => {
  const user = getUser();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Field name="contactName" component={Input} label="Contact name" />
      <Field name="customerEmail" component={Input} label="Customer email" />
      <Field name="customerPhone" component={Input} label="Customer phone" type="tel" />
      {!user.hasIncumbentRebateProgram && (
        <Field
          name="currentSpending"
          component={Input}
          label="Current spending for all services"
          type="number"
          normalize={toNumberIfNotEmptyString}
        />
      )}
      <Field name="exContractExpireDate" label="Renewal Date" component={DatePicker} disabledDays={disabledDates} />
      <Field name="followUpDate" label="Follow Up Date" component={DatePicker} disabledDays={disabledDates} />
      <ButtonSet margin="large no no">
        <Button color="primary">Generate quote</Button>
      </ButtonSet>
    </form>
  );
};

CustomerEditorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'locationEditor',
  validate,
  asyncValidate: isAvailablePhone,
  asyncChangeFields: ['customerPhone'],
})(CustomerEditorForm);
