import update from 'immutability-helper';
import { resetPassword as doResetPassword, changePassword as doChangePassword } from '../services/account';

// Actions
const START_RESET_PASSWORD = 'account/resetPassword/START_RESET_PASSWORD';
const COMPLETE_RESET_PASSWORD = 'account/resetPassword/COMPLETE_RESET_PASSWORD';
const FAIL_RESET_PASSWORD = 'account/resetPassword/FAIL_RESET_PASSWORD';
const START_CHANGE_PASSWORD = 'account/changePassword/START_CHANGE_PASSWORD';
const COMPLETE_CHANGE_PASSWORD = 'account/changePassword/COMPLETE_CHANGE_PASSWORD';
const FAIL_CHANGE_PASSWORD = 'account/changePassword/FAIL_CHANGE_PASSWORD';
const RESET = 'account/resetPassword/RESET';

// Initial state
const initialState = {
  isResettingPassword: false,
  isResetPasswordFailed: false,
  isChangeingPassword: false,
  isChangeingPasswordFailed: false,
  errorMessage: '',
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_RESET_PASSWORD:
      return update(state, {
        $merge: { isResettingPassword: true },
      });

    case COMPLETE_RESET_PASSWORD:
      return update(state, {
        $merge: { isResettingPassword: false },
      });

    case FAIL_RESET_PASSWORD:
      return update(state, {
        $merge: {
          isResetPasswordFailed: true,
          isResettingPassword: false,
          errorMessage: action.error.response.data.exceptionMessage,
        },
      });

    case START_CHANGE_PASSWORD:
      return update(state, {
        $merge: { isChangeingPassword: true },
      });

    case COMPLETE_CHANGE_PASSWORD:
      return update(state, {
        $merge: { isChangeingPassword: false },
      });

    case FAIL_CHANGE_PASSWORD:
      return update(state, {
        $merge: {
          isChangeingPasswordFailed: true,
          isChangeingPassword: false,
          errorMessage: action.error.response.data.exceptionMessage,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startResetPassword = () => ({
  type: START_RESET_PASSWORD,
});

const completeResetPassword = () => ({
  type: COMPLETE_RESET_PASSWORD,
});

const failResetPassword = error => ({
  type: FAIL_RESET_PASSWORD,
  error,
});

const startChangePassword = () => ({
  type: START_CHANGE_PASSWORD,
});

const completeChangePassword = () => ({
  type: COMPLETE_CHANGE_PASSWORD,
});

const failChangePassword = error => ({
  type: FAIL_CHANGE_PASSWORD,
  error,
});

export const resetPassword = (token, email, password) => dispatch => {
  dispatch(startResetPassword());
  const resetPasswordPromise = doResetPassword(token, email, password);
  resetPasswordPromise.then(() => dispatch(completeResetPassword())).catch(error => dispatch(failResetPassword(error)));
  return resetPasswordPromise;
};

export const changePassword = (oldPassword, newPassword) => dispatch => {
  dispatch(startChangePassword());
  const changePasswordPromise = doChangePassword(oldPassword, newPassword);
  changePasswordPromise
    .then(() => dispatch(completeChangePassword()))
    .catch(error => dispatch(failChangePassword(error)));
  return changePasswordPromise;
};

export const resetResetPassword = () => ({
  type: RESET,
});
