import React from 'react';
import PropTypes from 'prop-types';
import {
  MobileNavBarSideContainer,
  MobileNavBarSideTop,
  MobileNavBarSiteClose,
  MobileNavBarLogo,
  MobileNavItemsContainer,
  MobileNavLogout,
  MobileNavAccountContainer,
  MobileNavAccountButton,
} from '../styled/MobileNavBar';
import NavigationItems from './NavigationItems';
import MobileNavBarItem from './MobileNavBarItem';
import { navItemsMetadata } from '../../navigation';
import { defaultItem } from '../../navigation/navigationItems';
import { NavigationBarIcon } from '../styled';

function haltEvent(event) {
  event.stopPropagation();
}

const MobileSideNavBar = ({ username, isPartnerSuperAdmin, closeHamburger, openUserProfileModal }) => {
  const { logout } = navItemsMetadata;
  return (
    <MobileNavBarSideContainer onClick={haltEvent}>
      <MobileNavBarSideTop>
        <MobileNavBarSiteClose onClick={closeHamburger}>X</MobileNavBarSiteClose>
        <MobileNavBarLogo to={defaultItem.to} onClick={closeHamburger} />
      </MobileNavBarSideTop>

      <MobileNavItemsContainer>
        <NavigationItems ItemComponent={MobileNavBarItem} onClick={closeHamburger} />
      </MobileNavItemsContainer>

      <MobileNavAccountContainer>
        {!isPartnerSuperAdmin && (
          <MobileNavAccountButton
            onClick={() => {
              closeHamburger();
              openUserProfileModal();
            }}
          >
            <NavigationBarIcon icon="settingsCircle" />
            {username}
          </MobileNavAccountButton>
        )}
        <MobileNavLogout to={logout.to}>{logout.caption}</MobileNavLogout>
      </MobileNavAccountContainer>
    </MobileNavBarSideContainer>
  );
};

MobileSideNavBar.propTypes = {
  username: PropTypes.string.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  closeHamburger: PropTypes.func.isRequired,
  openUserProfileModal: PropTypes.func.isRequired,
};

export default MobileSideNavBar;
