import { css } from 'styled-components';
import { DESKTOP_MAX_WIDTH, TABLET_MAX_WIDTH, PHONE_MAX_WIDTH } from '../../core/styles';

const custom = maxWidth => (...args) => css`
  @media (max-width: ${maxWidth}px) {
    ${css(...args)};
  }
`;

const desktop = custom(DESKTOP_MAX_WIDTH);
const tablet = custom(TABLET_MAX_WIDTH);
const phone = custom(PHONE_MAX_WIDTH);

const media = {
  desktop,
  tablet,
  phone,
  custom,
};

export default media;
