import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Script extends Component {
  static scriptObservers = {};

  static loadedScripts = {};

  static erroredScripts = {};

  static idCount = 0;

  constructor(props) {
    super(props);
    this.scriptLoaderId = `id${Script.idCount++}`;
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    const { name, onError, onLoad } = this.props;

    if (Script.loadedScripts[name]) {
      if (onLoad) {
        onLoad();
      }
      this.setState({ isLoaded: true });
      return;
    }

    if (Script.erroredScripts[name]) {
      if (onError) {
        onError();
      }
      this.setState({ isLoaded: true });
      return;
    }

    if (Script.scriptObservers[name]) {
      Script.scriptObservers[name][this.scriptLoaderId] = this.props;
      return;
    }

    Script.scriptObservers[name] = {
      [this.scriptLoaderId]: this.props,
    };

    this.createScript();
  }

  componentWillUnmount() {
    const { name } = this.props;
    const observers = Script.scriptObservers[name];
    if (observers) {
      delete observers[this.scriptLoaderId];
    }
  }

  onScriptLoaded = error => {
    const { name } = this.props;
    const observers = Script.scriptObservers[name];
    Object.keys(observers).forEach(key => {
      if (error) {
        const observer = observers[key];
        Script.erroredScripts[name] = true;
        console.log(`Script ${name} failed to load!`);
        if (observer.onError) {
          observer.onError();
        }
      } else {
        const observer = observers[key];
        Script.loadedScripts[name] = true;
        console.log(`Script ${name} loaded successfully`);
        if (observer.onLoad) {
          observer.onLoad();
        }
      }
    });
    this.setState({ isLoaded: true });
    delete Script.scriptObservers[name][this.scriptLoaderId];
  };

  createScript() {
    const { isUrl, content, onCreate, attributes, appendToHead } = this.props;
    const script = document.createElement('script');

    if (onCreate) {
      onCreate();
    }

    if (attributes) {
      Object.keys(attributes).forEach(prop => script.setAttribute(prop, attributes[prop]));
    }
    if (isUrl) {
      script.src = content;
    } else {
      script.text = content;
    }
    if (!script.hasAttribute('async')) {
      script.async = true;
    }

    if (appendToHead) {
      document.head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
    if (isUrl) {
      script.onload = () => {
        this.onScriptLoaded();
      };
      script.onerror = () => {
        this.onScriptLoaded(true);
      };
    } else {
      this.onScriptLoaded();
    }
  }

  render() {
    const { children } = this.props;
    if (children) {
      const { isLoaded } = this.state;
      if (isLoaded) {
        return <>{children}</>;
      }
    }
    return null;
  }
}

Script.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isUrl: PropTypes.bool,
  attributes: PropTypes.object,
  onCreate: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
  appendToHead: PropTypes.bool,
};

Script.defaultProps = {
  isUrl: false,
  attributes: {},
  onCreate: undefined,
  onError: undefined,
  onLoad: undefined,
  appendToHead: undefined,
};

export default Script;
