import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { size } from 'lodash-es';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import {
  Button,
  Panel,
  PanelSection,
  TableRow,
  TableCell,
  TableActions,
  TableActionButton,
  TableActionIcon,
  Message,
} from '../../../core/components/styled';
import { Table, Pagination } from '../../../core/components';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { ROLES } from '../../../account/constants';
import { SuperAdminGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { UsersForm } from '../forms';
import { UserEditorModalResolver } from '../modals';
import { loadUsers, resetUsers, saveUser, deleteUser } from '../../ducks';
import confirm from '../../../core/services/confirm';

const LIMIT_PER_PAGE = 20;

const UsersTableRow = ({
  loggedInUserId,
  userId,
  company,
  firstName,
  lastName,
  role,
  email,
  phoneNumber,
  deleteUser,
  editUser,
  tableCellWidths,
}) => (
  <TableRow>
    <SuperAdminGuard>
      <TableCell width={tableCellWidths[0]}>{company.companyName}</TableCell>
    </SuperAdminGuard>
    <TableCell width={tableCellWidths[1]}>{`${firstName} ${lastName}`}</TableCell>
    <TableCell width={tableCellWidths[2]}>{ROLES[role].name}</TableCell>
    <TableCell width={tableCellWidths[3]}>{email}</TableCell>
    <TableCell width={tableCellWidths[4]}>{phoneNumber}</TableCell>
    <TableCell width={tableCellWidths[5]}>
      {loggedInUserId !== userId && (
        <TableActions align="left">
          <TableActionButton onClick={event => editUser(event, userId)}>
            <TableActionIcon icon="edit" />
          </TableActionButton>

          <TableActionButton onClick={() => deleteUser(userId)}>
            <TableActionIcon icon="delete" />
          </TableActionButton>
        </TableActions>
      )}
    </TableCell>
  </TableRow>
);

UsersTableRow.propTypes = {
  loggedInUserId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  tableCellWidths: PropTypes.array.isRequired,
  deleteUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
};

class UsersPage extends PureComponent {
  state = { isUserEditorModalOpen: false };

  componentDidMount() {
    const {
      history,
      loadUsers,
      location: { pathname },
    } = this.props;
    this.unlistenHistory = history.listen(location => {
      if (pathname === location.pathname) {
        const { page, limit, sortOrder, sortedBy, searchTerm } = getQueryParams(location.search);
        loadUsers(page, limit, sortOrder, sortedBy, searchTerm);
      }
    });
  }

  componentWillUnmount() {
    const { resetUsers } = this.props;
    this.unlistenHistory();
    resetUsers();
  }

  onSearchTermChange = searchTerm => {
    const {
      location: { pathname, search },
      push,
    } = this.props;
    push(
      createUrl(pathname, search, {
        searchTerm,
        page: undefined,
      }),
    );
  };

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  saveUser = user => {
    const { saveUser, loadUsers, location } = this.props;
    const { page, limit, sortOrder, sortedBy, searchTerm } = getQueryParams(location.search);
    saveUser(user)
      .then(async () => {
        createSuccessNotification(`User saved succesfully`);
        await loadUsers(page, limit, sortOrder, sortedBy, searchTerm);
        this.closeUserEditorModal();
      })
      .catch(() => createErrorNotification('User was not saved.Please try again'));
  };

  deleteUser = async userId => {
    if (!(await confirm('Are you sure you want to delete user?'))) {
      return;
    }

    const { deleteUser } = this.props;
    try {
      await deleteUser(userId);
      createSuccessNotification('User deleted');
    } catch (e) {
      createErrorNotification('There was an error trying to delete the user.Please try again');
    }
  };

  openUserEditorModal = (event, userId) => {
    event.stopPropagation();
    this.setState({ isUserEditorModalOpen: true, userId });
  };

  closeUserEditorModal = () => {
    this.setState({ isUserEditorModalOpen: false, userId: undefined });
  };

  render() {
    const {
      loggedInUserId,
      isLoading,
      isPartnerSuperAdmin,
      users,
      total,
      location: { search },
    } = this.props;
    const { isUserEditorModalOpen, userId } = this.state;
    const { sortOrder, sortedBy, searchTerm } = getQueryParams(search);

    const tableCellWidths = isPartnerSuperAdmin
      ? ['18%', '15%', '15%', '30%', '15%', '7%']
      : [undefined, '28%', '15%', '30%', '20%', '7%'];

    const usersTableCells = [
      { name: 'companyName', label: 'Partner company', width: tableCellWidths[0], sortable: true },
      { name: 'userName', label: 'User name', width: tableCellWidths[1], sortable: true },
      { name: 'userRoleName', label: 'User role', width: tableCellWidths[2], sortable: true },
      { name: 'email', label: 'Email', width: tableCellWidths[3], sortable: true },
      { name: 'phone', label: 'Phone', width: tableCellWidths[4] },
      { name: 'options', width: tableCellWidths[5], align: 'right' },
    ];

    if (!isPartnerSuperAdmin) {
      usersTableCells.shift();
    }

    return (
      <>
        <PageHeader>
          <PageTitle>Users List</PageTitle>
          <PageActions>
            <Button color="primary" onClick={this.openUserEditorModal}>
              Add user
            </Button>
          </PageActions>
        </PageHeader>

        <Panel isLoading={isLoading}>
          <UsersForm onSearchTermChange={this.onSearchTermChange} initialValues={{ searchTerm }} />

          {!!size(users) && (
            <PanelSection>
              <Table
                cells={usersTableCells}
                rows={users}
                rowComponent={UsersTableRow}
                rowProps={{
                  loggedInUserId,
                  deleteUser: this.deleteUser,
                  editUser: this.openUserEditorModal,
                  tableCellWidths,
                }}
                sort={this.onSortOrderChange}
                sortOrder={sortOrder}
                sortedBy={sortedBy}
              />
            </PanelSection>
          )}
          {!size(users) && <Message padding="sMedium">There are no users</Message>}
          {total > LIMIT_PER_PAGE && (
            <PanelSection>
              <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} maxPaginationItems={10} />
            </PanelSection>
          )}
        </Panel>
        {isUserEditorModalOpen && (
          <UserEditorModalResolver userId={userId} saveUser={this.saveUser} closeModal={this.closeUserEditorModal} />
        )}
      </>
    );
  }
}

UsersPage.propTypes = {
  loggedInUserId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
  location: RouterPropTypes.location.isRequired,
  loadUsers: PropTypes.func.isRequired,
  resetUsers: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loggedInUserId: state.account.login.user.userId,
  isLoading: state.users.users.isLoading,
  users: state.users.users.users,
  total: state.users.users.total,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadUsers, resetUsers, saveUser, deleteUser, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UsersPage),
);
