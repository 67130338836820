import styled from 'styled-components';
import { transparentize, setLightness, setSaturation } from 'polished';
import { colorMapper } from '../../../utils/styles';
import { fadeAndSlideInUp } from '../../styles';
import { Icon } from '..';
import { BareButtonStyle } from './Button';

const createNotificationBackgroundColor = color => setSaturation(0.2, setLightness(0.96, color));
const createNotificationBorderColor = color => setSaturation(0.3, setLightness(0.82, color));

export const NotificationCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
  margin-top: -6px;
  vertical-align: middle;
`;

export const NotificationClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  color: #fff;
`;

export const Notification = styled.div`
  position: relative;
  width: 330px;
  margin-bottom: 20px;
  padding: 15px 35px 18px 15px;
  background-color: ${props => createNotificationBackgroundColor(colorMapper(props))};
  border-radius: 4px;
  border: 1px solid ${props => createNotificationBorderColor(colorMapper(props))};
  box-shadow: 0 6px 16px ${transparentize(0.8, '#000')};
  line-height: 20px;
  font-size: 15px;
  color: ${colorMapper};
  animation: 0.3s ease-out ${fadeAndSlideInUp};

  ${NotificationClose} {
    background-color: ${colorMapper};
  }
`;

export const NotificationList = styled.div`
  position: fixed;
  top: 16px;
  right: 10px;
  z-index: 1;
`;
