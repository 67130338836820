import moment from 'moment';
import { map, size, filter } from 'lodash-es';

export const transformLoadOpportunities = ({ quotes, total }) => ({
  opportunities: quotes,
  total,
});

const getTimeLineEvents = soldInformation => {
  if (soldInformation) {
    const numberOfEvents = soldInformation.hasCancelation ? 4 : 3;
    const purchasedInfo = soldInformation.purchaseDate
      ? { isReached: true, date: moment(soldInformation.purchaseDate).format('MM/DD/YYYY') }
      : { isReached: false, date: 'TBD' };

    const cancellationInfo = soldInformation.cancelationLetterDate
      ? { isReached: true, date: moment(soldInformation.cancelationLetterDate).format('MM/DD/YYYY') }
      : { isReached: false, date: 'TBD' };

    const scheduledInfo = soldInformation.scheduledDeliveryDate
      ? { isReached: true, date: moment(soldInformation.scheduledDeliveryDate).format('MM/DD/YYYY') }
      : { isReached: false, date: 'TBD' };

    const startInfo = soldInformation.serviceStartDate
      ? { isReached: true, date: moment(soldInformation.serviceStartDate).format('MM/DD/YYYY') }
      : { isReached: false, date: 'TBD' };

    const completedEvents = size(
      filter([purchasedInfo, scheduledInfo, startInfo, cancellationInfo], ({ isReached }) => isReached),
    );

    const timelineProgress =
      completedEvents === numberOfEvents ? 100 : ((completedEvents * 2 - 1) * 100) / (numberOfEvents * 2);

    return {
      purchasedInfo,
      cancellationInfo,
      scheduledInfo,
      startInfo,
      hasCancelation: soldInformation.hasCancelation,
      timelineProgress,
    };
  }
  return null;
};

export const transformLoadOpportunity = ({ locations, ...opportunity }) => ({
  ...opportunity,
  locations: map(locations, ({ services, ...location }) => ({
    ...location,
    services: map(services, ({ sold, ...service }) => ({
      ...service,
      sold: getTimeLineEvents(sold),
    })),
  })),
});
