import { createSelector } from 'reselect';
import { identity } from 'lodash-es';
import getMobilePageTitle from '../services/getMobilePageTitle';

const mobilePageTitle = locationState => getMobilePageTitle(locationState.pathname);

// Selectors
export const mobilePageTitleSelector = createSelector(
  mobilePageTitle,
  identity,
);
