import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelTitle, AccountPanelDescription, AccountPanelError } from '../styled';
import { isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const matchPassword = (value, allValues) => (value === allValues.password ? undefined : 'Passwords do not match.');

const AcceptInvitationForm = ({ handleSubmit, isAcceptInvitationFailed, errorMessage }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelTitle>Welcome!</AccountPanelTitle>
    <AccountPanelDescription>Please set your password</AccountPanelDescription>

    <Field name="password" component={Input} label="Password" type="password" validate={[isRequired]} />

    <Field
      name="passwordConfirmation"
      component={Input}
      label="Confirm  password"
      type="password"
      validate={[isRequired, matchPassword]}
    />
    {isAcceptInvitationFailed && <AccountPanelError>{errorMessage}</AccountPanelError>}
    <ButtonSet>
      <Button type="submit" color="primary">
        Accept invitation
      </Button>
    </ButtonSet>
  </form>
);

AcceptInvitationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAcceptInvitationFailed: PropTypes.bool.isRequired,
  errorMessage: PropTypes.any.isRequired,
};

export default reduxForm({
  form: 'acceptInvitation',
  onSubmitFail: focusFirstInvalidField,
})(AcceptInvitationForm);
