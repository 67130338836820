import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Confirmation from './Confirmation';

class NavigationPrompt extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { nextLocation: null, isConfirmationShown: false };
  }

  componentDidMount() {
    const { history, when } = this.props;
    this.unblock = history.block(nextLocation => {
      if (when) {
        this.setState({
          isConfirmationShown: true,
          nextLocation,
        });
      }

      return !when;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  onCancel = () =>
    this.setState({
      nextLocation: null,
      isConfirmationShown: false,
    });

  onConfirm = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;

    this.unblock();
    history.push(nextLocation.pathname);
  };

  render() {
    const { isConfirmationShown } = this.state;
    return (
      <>
        {isConfirmationShown && (
          <Confirmation
            title="Are you sure you want to leave this page?"
            message="All your changes will be lost."
            resolve={this.onConfirm}
            reject={this.onCancel}
          />
        )}
      </>
    );
  }
}

NavigationPrompt.propTypes = {
  history: PropTypes.object.isRequired,
  when: PropTypes.bool.isRequired,
};

export default withRouter(NavigationPrompt);
