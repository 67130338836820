import styled, { css } from 'styled-components';
import GreenPin from '../../assets/img/pin_green.svg';
import RedPin from '../../assets/img/pin_red.svg';

export const SimpleMarkerStyled = styled.div`
  background-image: url(${RedPin});
  background-size: 100%;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 27px;
  top: -10px;
  left: -27px;
  transform-origin: 10px 27px;
  margin: 0;
  padding: 0;

  ${props =>
    props.isChecked &&
    css`
      background-image: url(${GreenPin});
    `}
`;
