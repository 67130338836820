import { createSelector } from 'reselect';
import { identity, find } from 'lodash-es';
import { PARTNER_MANAGER, PARTNER_USER } from '../../account/constants';

const userEditorFormInitialValues = (userState, loginState) => {
  const { user } = userState;

  if (user) {
    const {
      userId,
      firstName,
      lastName,
      company: { id: companyId },
      role,
      phoneNumber,
      email,
    } = user;
    return {
      userId,
      firstName,
      lastName,
      companyId: companyId && companyId.toString(),
      role,
      email,
      phoneNumber,
    };
  }

  const {
    user: {
      roles,
      company: { id: companyId },
    },
  } = loginState;

  const isPartnerManager = !!find(roles, role => role === PARTNER_MANAGER);

  return {
    companyId: companyId && companyId.toString(),
    role: isPartnerManager && PARTNER_USER,
  };
};

const userEditorFormInitialValuesSelector = createSelector(
  userEditorFormInitialValues,
  identity,
);

export default userEditorFormInitialValuesSelector;
