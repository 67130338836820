import React from 'react';
import { ButtonSet, ButtonLink, Container } from '../../../core/components/styled';
import { CheckmarkImage } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { AccountPanelDescription } from '../styled';

const PasswordRequestSentPage = () => (
  <>
    <DocumentTitle>Forgot your password?</DocumentTitle>

    <Container align="center" margin="large no">
      <CheckmarkImage />
    </Container>
    <AccountPanelDescription>
      We have sent you an email with instructions on how to reset your password
    </AccountPanelDescription>

    <ButtonSet>
      <ButtonLink to="/account/login" color="primary">
        Back to login
      </ButtonLink>
    </ButtonSet>
  </>
);

export default PasswordRequestSentPage;
