import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { sizeMapper, media } from '../../../utils/styles';
import { BareButtonStyle } from './Button';
import { Icon } from '..';

export const CardError = styled.span`
  position: absolute;
  bottom: 4px;
  right: 4px;
  display: block;
  padding: 2px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 10px;
  background-color: ${props => props.theme.brandAlert};
  color: #fff;
`;

export const CardTitle = styled.span`
  width: 100%;
  margin-bottom: 15px;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 24px;

  ${props =>
    props.smallCard &&
    css`
      margin-bottom: 30px;
    `};

  ${props =>
    props.hasRebate &&
    props.smallCard &&
    css`
      margin-bottom: 40px;
    `};

  ${media.tablet`
    font-size: 18px;
  `}

  ${media.phone`
    margin-bottom: 45px;
  `}
`;

export const CardImageContainer = styled.div`
  flex-basis: 30%;
  max-width: 140px;
`;

export const CardActionIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`;

export const CardAction = styled.button`
  ${BareButtonStyle};
  width: 14px;
  height: 14px;
  color: ${props => props.theme.brandPrimary};
`;

export const CardActions = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
`;

export const CardExtraDetailLabel = styled.span`
  display: block;
  font-size: 13px;
  color: ${props => props.theme.grayDark};
  ${props =>
    props.smallCard &&
    css`
      font-size: 12px;
    `};
`;

export const CardExtraDetailValue = styled.span`
  display: block;
  margin-bottom: 5px;
  font-size: 26px;
  color: ${props => props.theme.brandPrimary};
  ${props =>
    props.smallCard &&
    css`
      font-size: 16px;
    `};

  ${media.phone`
     font-size: 20px;
  `}
`;

export const CardExtraDetail = styled.div`
  margin-right: 15px;
  text-align: right;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardExtraDetails = styled.div`
  position: absolute;
  top: 35px;
  right: 10px;
  display: flex;
`;

export const CardDetailLabel = styled.span`
  display: block;
  text-transform: capitalize;
  line-height: 18px;
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: 12px;
  color: ${props => props.theme.grayDark};
`;

export const CardDetailValue = styled.span`
  display: block;
  line-height: 18px;
  font-size: 13px;
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const CardDetail = styled.div`
  margin: 0 20px 8px 0;
  ${props =>
    props.width &&
    css`
      flex-basis: ${props.width};
      margin-right: 0;
    `};
`;

export const CardDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  max-width: 300px;
  padding: 15px 25px 5px 25px;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: ${props => sizeMapper(props.margin, 'no no small')};
  background-color: #fff;
  box-shadow: 0 0 8px ${transparentize(0.85, '#000')};
  border-radius: 4px;
  border: 1px solid transparent;
  transform: none;
  transition: transform 0.2s linear, border-color 0.3s ease-out, box-shadow 0.3s ease-out;

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.isSelected &&
    css`
      border-color: ${props => props.theme.brandPrimary};
    `};

  ${props =>
    props.hasError &&
    css`
      ${CardDetails} {
        padding-bottom: 25px;
      }
    `};
`;
