import { identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { FRONT_LOAD } from '../../common/constants';
import { locationByServiceIdSelector } from '../ducks';

const getServiceEditorFormResetValues = (quoteState, locationId, serviceId) => {
  const location = serviceId ? locationByServiceIdSelector(quoteState, serviceId) : quoteState.locations[locationId];
  const service = { serviceType: FRONT_LOAD };

  return {
    location,
    service,
  };
};

const serviceEditorFormResetValuesSelector = createSelector(
  getServiceEditorFormResetValues,
  identity,
);

export default serviceEditorFormResetValuesSelector;
