import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { PanelSection } from '../../../core/components/styled';
import { DocumentTitle } from '../../../common/components';
import { getQueryParams } from '../../../utils/services/queryParams';
import { login, resetPassword, resetResetPassword } from '../../ducks';
import { ResetPasswordForm } from '../forms';

class ResetPasswordPage extends PureComponent {
  componentWillUnmount() {
    const { resetResetPassword } = this.props;
    resetResetPassword();
  }

  onFormSubmit = ({ password }) => {
    const { resetPassword, location, login, push } = this.props;
    const { email, code } = getQueryParams(location.search);
    resetPassword(code, email, password)
      .then(async () => {
        await login(email, password);
        push('/opportunities');
      })
      .catch(() => {});
  };

  render() {
    const { isResettingPassword, errorMessage, isResetPasswordFailed } = this.props;
    return (
      <>
        <DocumentTitle>Reset password</DocumentTitle>
        <PanelSection isLoading={isResettingPassword}>
          <ResetPasswordForm
            onSubmit={this.onFormSubmit}
            errorMessage={errorMessage}
            isResetPasswordFailed={isResetPasswordFailed}
          />
        </PanelSection>
      </>
    );
  }
}

ResetPasswordPage.propTypes = {
  isResettingPassword: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resetResetPassword: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isResetPasswordFailed: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({
  resetPassword: state.account.resetPassword,
  isResetPasswordFailed: state.account.resetPassword.isResetPasswordFailed,
  errorMessage: state.account.resetPassword.errorMessage,
});

const mapDispatchToProps = {
  resetPassword,
  resetResetPassword,
  login,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordPage);
