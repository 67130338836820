import { mapKeys } from 'lodash-es';

export const PARTNER_SUPER_ADMIN = 'RubiconPartnerSuperAdmin';
export const PARTNER_ADMIN = 'RubiconPartnerAdmin';
export const PARTNER_MANAGER = 'RubiconPartnerManager';
export const PARTNER_USER = 'RubiconPartnerUser';

export const ROLES = mapKeys(
  [
    { name: 'Partner super admin', value: PARTNER_SUPER_ADMIN },
    { name: 'Partner admin', value: PARTNER_ADMIN },
    { name: 'Partner manager', value: PARTNER_MANAGER },
    { name: 'Partner user', value: PARTNER_USER },
  ],
  'value',
);
