import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { Field, reduxForm } from 'redux-form';
import { PanelSection } from '../../../core/components/styled';
import { PanelSearch } from '../../../core/components';

class UsersForm extends PureComponent {
  onSearchTermChange = debounce((event, searchTerm) => {
    const { onSearchTermChange } = this.props;
    onSearchTermChange(searchTerm);
  }, 600);

  render() {
    return (
      <form>
        <PanelSection padding="xSmall">
          <Field name="searchTerm" component={PanelSearch} onChange={this.onSearchTermChange} margin="no" />
        </PanelSection>
      </form>
    );
  }
}

UsersForm.propTypes = {
  onSearchTermChange: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'users',
})(UsersForm);
