import update from 'immutability-helper';
import { filter, map, uniq, omit, omitBy, sortBy, orderBy, isNil, identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { FRONT_LOAD, CART } from '../constants';
import doLoadServiceOptions from '../services/loadServiceOptions';

// Actions
const START_LOAD = 'common/serviceOptions/START_LOAD';
const COMPLETE_LOAD = 'common/serviceOptions/COMPLETE_LOAD';
const FAIL_LOAD = 'common/serviceOptions/FAIL_LOAD';
const RESET = 'common/serviceOptions/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadingfailed: false,
  serviceOptions: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isLoadingfailed: false,
          serviceOptions: action.serviceOptions,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isLoadingfailed: true,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = serviceOptions => ({
  type: COMPLETE_LOAD,
  serviceOptions,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadServiceOptions = address => dispatch => {
  dispatch(startLoad());

  const loadServiceOptionsPromise = doLoadServiceOptions(address);
  loadServiceOptionsPromise
    .then(serviceOptions => dispatch(completeLoad(serviceOptions)))
    .catch(() => dispatch(failLoad()));

  return loadServiceOptionsPromise;
};

export const resetServiceOptions = () => ({
  type: RESET,
});

// Selectors
const getServiceOptionsForZipCode = (state, zipCode) => state.serviceOptions && state.serviceOptions[zipCode];

const getServiceTypesForZipCode = (state, zipCode) => {
  const serviceOptionsForZipCode = getServiceOptionsForZipCode(state, zipCode);
  return serviceOptionsForZipCode ? sortBy(uniq(map(serviceOptionsForZipCode, 'serviceType'))) : undefined;
};

const getServiceOptionsForZipCodeAndFilter = (state, zipCode, filters) => {
  const serviceOptionsForZipCode = getServiceOptionsForZipCode(state, zipCode);

  if (!serviceOptionsForZipCode) return;

  const validFilters = omitBy(filters, filter => !filter);
  const containerSizeFilters = omit(validFilters, 'containerSize');
  const wasteTypeFilters = omit(validFilters, 'wasteType');
  const recurringFrequencyFilters = omit(validFilters, 'recurringFrequency');

  const filteredServiceOptions = filter(serviceOptionsForZipCode, validFilters);

  const containerSizeOptions = filter(serviceOptionsForZipCode, containerSizeFilters);
  const wasteTypeOptions = filter(serviceOptionsForZipCode, wasteTypeFilters);
  const recurringFrequencyOptions = filter(serviceOptionsForZipCode, recurringFrequencyFilters);

  const containerSizes = sortBy(uniq(map(containerSizeOptions, 'containerSize')));
  const wasteTypes = uniq(map(wasteTypeOptions, 'wasteType'));
  const recurringFrequencies = sortBy(uniq(map(recurringFrequencyOptions, 'recurringFrequency')));

  const startDateDelayWithSalesContract = filteredServiceOptions.length
    ? filteredServiceOptions[0].startDateDelayWithSalesContract
    : undefined;

  const startDateDelayWithoutSalesContract = filteredServiceOptions.length
    ? filteredServiceOptions[0].startDateDelayWithoutSalesContract
    : undefined;

  return {
    containerSizes,
    wasteTypes,
    recurringFrequencies,
    startDateDelayWithSalesContract,
    startDateDelayWithoutSalesContract,
  };
};

const getFrontLoadServiceOptions = (state, zipCode, wasteType, containerSize, recurringFrequency) =>
  getServiceOptionsForZipCodeAndFilter(state, zipCode, {
    serviceType: FRONT_LOAD,
    wasteType,
    containerSize,
    recurringFrequency,
  });

const getCartServiceOptions = (state, zipCode, wasteType, containerSize, recurringFrequency) =>
  getServiceOptionsForZipCodeAndFilter(state, zipCode, {
    serviceType: CART,
    wasteType,
    containerSize,
    recurringFrequency,
  });

const getServiceOptionsByServiceType = (state, zipCode, serviceType, serviceValues) => {
  const { wasteType, containerSize, recurringFrequency } = serviceValues;

  return serviceType === FRONT_LOAD
    ? getFrontLoadServiceOptions(state, zipCode, wasteType, containerSize, recurringFrequency)
    : getCartServiceOptions(state, zipCode, wasteType, containerSize, recurringFrequency);
};

const getServiceRulesForZipCodeAndFilter = (state, zipCode, filters) => {
  const serviceOptionsForZipCode = getServiceOptionsForZipCode(state, zipCode);

  if (!serviceOptionsForZipCode) return;

  const validFilters = omitBy(filters, isNil);
  return orderBy(filter(serviceOptionsForZipCode, validFilters), [
    'serviceType',
    'wasteType',
    'recurringFrequency',
    'containerSize',
  ]);
};

export const serviceTypesSelector = createSelector(
  getServiceTypesForZipCode,
  identity,
);

export const serviceOptionsSelector = createSelector(
  getServiceOptionsByServiceType,
  identity,
);

export const frontLoadServiceOptionsSelector = createSelector(
  getFrontLoadServiceOptions,
  identity,
);

export const cartServiceOptionsSelector = createSelector(
  getCartServiceOptions,
  identity,
);

export const serviceRulesForZipCodeAndFilterSelector = createSelector(
  getServiceRulesForZipCodeAndFilter,
  identity,
);
