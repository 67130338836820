import axios from 'axios';
import humps from 'humps';
import { isPlainObject, isArray } from 'lodash-es';
import { getApiBaseURL } from './environment';
import { API_KEY } from '../constants';

const transformResponse = data => (isPlainObject(data) || isArray(data) ? humps.camelizeKeys(data) : data);

const defaultHttpSettings = {
  responseType: 'json',
  headers: {
    'ocp-apim-subscription-key': API_KEY,
  },
  transformResponse: axios.defaults.transformResponse.concat(transformResponse),
};

const http = axios.create({
  ...defaultHttpSettings,
  baseURL: `${getApiBaseURL()}/`,
});

export default http;
