import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshAuthToken } from '../ducks';
import { PageLoading } from '../../common/components/styled';

class AutoLogin extends PureComponent {
  constructor(props) {
    super(props);
    const { isLoggedIn, canRefreshAuthToken } = props;
    this.state = { showChildren: isLoggedIn || !canRefreshAuthToken };
  }

  componentDidMount() {
    const { canRefreshAuthToken } = this.props;
    if (canRefreshAuthToken) {
      this.refreshAuthToken();
    }
  }

  refreshAuthToken = async () => {
    const { refreshAuthToken } = this.props;
    await refreshAuthToken();
    this.setState({
      showChildren: true,
    });
  };

  render() {
    const { children } = this.props;
    const { showChildren } = this.state;
    return showChildren ? children : <PageLoading />;
  }
}

AutoLogin.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  canRefreshAuthToken: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: state.account.login.isLoggedIn,
  canRefreshAuthToken: state.account.login.canRefreshAuthToken,
});

const mapDispatchToProps = { refreshAuthToken };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoLogin);
