import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { mapper, sizeMapper, media } from '../../../utils/styles';
import { fadeAndSlideInUp, loadingOverlay } from '../../styles';
import { Icon } from '..';
import { BareButtonStyle } from './Button';

export const ModalTitle = styled.h2`
  margin: ${props => sizeMapper(props.margin, 'no no medium')};
  line-height: 29px;
  text-transform: capitalize;
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-size: 24px;

  ${media.phone`
    font-size: 18px;
  `}
`;

export const ModalSubtitle = styled.span`
  margin: ${props => sizeMapper(props.margin, 'no no medium')};
  display: block;
  line-height: 16px;
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-size: 16px;
  color: ${props => props.theme.grayDark};
`;

export const ModalDialog = styled.div`
  position: relative;
  width: ${props => mapper(props.size, { small: '400px', medium: '600px', large: '800px', xLarge: '1000px' })};
  padding: ${props => sizeMapper(props.padding)};
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  animation: ${fadeAndSlideInUp} 0.3s ease-out;

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};

  ${props =>
    props.paddingTablet &&
    css`
      ${media.tablet`
        padding: ${props => sizeMapper(props.paddingTablet)};
      `};
    `};

  ${props =>
    props.paddingPhone &&
    css`
      ${media.phone`
        padding: ${props => sizeMapper(props.paddingPhone)};
      `};
    `};

  ${props =>
    props.sizeTablet &&
    css`
      ${media.custom(1000)`
        width: ${props =>
          mapper(props.sizeTablet, { small: '300px', medium: '400px', large: '750px', xLarge: '900px' })};
      `};
    `};

  ${props =>
    props.sizePhone &&
    css`
      ${media.phone`
        width: ${props => mapper(props.sizePhone, { small: '200px', medium: '370px', large: '400x', xLarge: '600px' })};
      `};
    `};
`;

export const ModalCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
`;

export const ModalClose = styled.button.attrs({ type: 'button' })`
  ${BareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.grayLight};
  border-radius: 4px;
  color: ${props => props.theme.grayDark};
  transition: background-color 0.3s ease-out;

  &:active {
    background-color: ${props => props.theme.gray};
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 5vh;
  background-color: ${props => transparentize(0.7, props.theme.grayBase)};

  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay('24px')};
      }
    `};
`;
