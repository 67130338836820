import { LOCAL, DEVELOPMENT, QA, PRODUCTION } from './environments';

export const BASE_URL_MAP = {
  'partner.rubicon.com': PRODUCTION,
  'partner.qa.aws.rubicon.com': QA,
  'partner.dev.aws.rubicon.com': DEVELOPMENT,
};

export const API_BASE_URL_MAP = {
  [PRODUCTION]: 'https://sales-api.prod.aws.rubicon.com',
  [QA]: 'https://sales-api.qa.aws.rubicon.com/',
  [DEVELOPMENT]: 'https://sales-api.dev.aws.rubicon.com',
  [LOCAL]: 'https://sales-api.dev.aws.rubicon.com',
};
