import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { map, get } from 'lodash-es';
import { Panel, Message, TableRow, TableCell, Text } from '../../../core/components/styled';
import { Table, Pagination } from '../../../core/components';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { localDate } from '../../../utils/services/formatter';
import { SuperAdminGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { SERVICE_PROVIDERS, DISQUALIFIED } from '../../../quote/constants';
import { loadOpportunities, resetOpportunities } from '../../ducks';

const LIMIT_PER_PAGE = 20;

const DisqualifiedOpportunitiesTableRow = ({
  lastEditDate,
  partnerName,
  partnerUserFirstName,
  partnerUserLastName,
  locations,
  tableCellWidths,
}) => (
  <TableRow>
    <TableCell width={tableCellWidths[0]}>{localDate(lastEditDate)}</TableCell>
    <SuperAdminGuard>
      <TableCell width={tableCellWidths[1]}>{partnerName}</TableCell>
    </SuperAdminGuard>
    <TableCell width={tableCellWidths[2]}>{`${partnerUserFirstName} ${partnerUserLastName}`}</TableCell>
    <TableCell width={tableCellWidths[3]}>
      {map(locations, ({ businessName, uid }) => (
        <Text block key={uid}>
          {businessName}
        </Text>
      ))}
    </TableCell>
    <TableCell width={tableCellWidths[4]}>
      {map(locations, ({ disqualifiedServiceProvider, serviceProviderId, uid }) => (
        <Text block key={uid}>
          {disqualifiedServiceProvider || get(SERVICE_PROVIDERS[serviceProviderId], 'name', '-')}
        </Text>
      ))}
    </TableCell>
  </TableRow>
);

DisqualifiedOpportunitiesTableRow.propTypes = {
  lastEditDate: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
  partnerUserFirstName: PropTypes.string.isRequired,
  partnerUserLastName: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  tableCellWidths: PropTypes.array.isRequired,
};
class DisqualifiedOpportunitiesSection extends PureComponent {
  componentDidMount() {
    const {
      history,
      loadOpportunities,
      location: { pathname },
    } = this.props;
    this.unlistenHistory = history.listen(location => {
      if (pathname === location.pathname) {
        const { page, limit, sortedBy, sortOrder } = getQueryParams(location.search);
        loadOpportunities(DISQUALIFIED, page, limit, sortedBy, sortOrder);
      }
    });
  }

  componentWillUnmount() {
    const { resetOpportunities } = this.props;
    this.unlistenHistory();
    resetOpportunities();
  }

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  render() {
    const {
      isLoading,
      opportunities,
      total,
      location: { search },
      isPartnerSuperAdmin,
    } = this.props;
    const { sortOrder, sortedBy } = getQueryParams(search);

    const tableCellWidths = isPartnerSuperAdmin
      ? ['20%', '20%', '20%', '20%', '20%']
      : ['25%', undefined, '25%', '25%', '25%'];

    const disqualifiedOpportunitiesTableCells = [
      { name: 'lastEditDate', label: 'Date ', width: tableCellWidths[0], sortable: true },
      { name: 'partnerName', label: 'Partner name', width: tableCellWidths[1], sortable: true },
      { name: 'userName', label: 'Name of user', width: tableCellWidths[2] },
      { name: 'businessName', label: 'Business name', width: tableCellWidths[3] },
      { name: 'serviceProvider', label: 'Current provider', width: tableCellWidths[4] },
    ];

    if (!isPartnerSuperAdmin) {
      disqualifiedOpportunitiesTableCells.splice(1, 1);
    }

    return (
      <Panel padding="no no xxSmall" isLoading={isLoading}>
        {total > 0 && (
          <Table
            cells={disqualifiedOpportunitiesTableCells}
            rows={opportunities}
            rowComponent={DisqualifiedOpportunitiesTableRow}
            rowProps={{ tableCellWidths }}
            sort={this.onSortOrderChange}
            sortOrder={sortOrder}
            sortedBy={sortedBy}
          />
        )}
        {total === 0 && <Message padding="sMedium">There are no diqualified opportunities</Message>}

        {total > LIMIT_PER_PAGE && (
          <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} maxPaginationItems={10} />
        )}
      </Panel>
    );
  }
}
DisqualifiedOpportunitiesSection.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  opportunities: PropTypes.array.isRequired,
  loadOpportunities: PropTypes.func.isRequired,
  resetOpportunities: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.opportunities.opportunities.isLoading,
  opportunities: state.opportunities.opportunities.opportunities,
  total: state.opportunities.opportunities.total,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadOpportunities, resetOpportunities, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DisqualifiedOpportunitiesSection),
);
