import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { iterator, media } from '../../../utils/styles';
import { fadeAndSlideInUp } from '../../../core/styles';
import {
  NEW_SERVICE,
  ALLIED_WASTE_SERVICES,
  AMERICAN_DISPOSAL_SERVICES,
  DEFFENBAUGH_INDUSTRIES,
  PROGRESSIVE_WASTE_SOLUTIONS,
  REPUBLIC_SERVICES,
  WASTE_CONNECTIONS,
  WEASTE_MANAGEMENT,
  GROOT,
} from '../../constants';
import newServiceProviderLogo from '../../assets/img/serviceProviderLogos/newServiceProvider.png';
import alliedWasteServicesLogo from '../../assets/img/serviceProviderLogos/alliedWasteServices.png';
import americanDisposalServicesLogo from '../../assets/img/serviceProviderLogos/americanDisposalServices.png';
import deffenbaughIndustriesLogo from '../../assets/img/serviceProviderLogos/deffenbaughIndustries.png';
import otherServiceProviderLogo from '../../assets/img/serviceProviderLogos/otherServiceProvider.png';
import progressiveWasteSolutionsLogo from '../../assets/img/serviceProviderLogos/progressiveWasteSolutions.png';
import republicServicesLogo from '../../assets/img/serviceProviderLogos/republicServices.png';
import wasteConnectionsLogo from '../../assets/img/serviceProviderLogos/wasteConnections.png';
import wasteManagementLogo from '../../assets/img/serviceProviderLogos/wasteManagement.png';
import grootLogo from '../../assets/img/serviceProviderLogos/groot.png';

const logos = {
  [NEW_SERVICE.id]: newServiceProviderLogo,
  [ALLIED_WASTE_SERVICES.id]: alliedWasteServicesLogo,
  [AMERICAN_DISPOSAL_SERVICES.id]: americanDisposalServicesLogo,
  [DEFFENBAUGH_INDUSTRIES.id]: deffenbaughIndustriesLogo,
  [PROGRESSIVE_WASTE_SOLUTIONS.id]: progressiveWasteSolutionsLogo,
  [REPUBLIC_SERVICES.id]: republicServicesLogo,
  [WASTE_CONNECTIONS.id]: wasteConnectionsLogo,
  [WEASTE_MANAGEMENT.id]: wasteManagementLogo,
  [GROOT.id]: grootLogo,
};

export const ServiceProviderName = styled.span`
  display: block;
  text-align: center;
  font-size: 12px;

  ${media.phone`
    font-size 11px;
  `}
`;

export const ServiceProviderNameInput = styled.input`
  width: 80%;
  margin: 0 10%;
  padding-bottom: 6px;
  outline: none;
  border: none;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  font-size: 12px;

  ${media.phone`
    font-size 11px;
  `}

  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.grayDark};
  }
`;

export const ServiceProviderLogo = styled.div`
  height: 114px;
  background: center no-repeat;
  background-size: 124px 70px;
`;

export const ServiceProviderOption = styled.div`
  position: relative;
  width: 23%;
  height: 150px;
  margin: 1%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.95, '#000')};
  box-shadow: none;
  transform: none;
  transition: transform 0.2s linear, box-shadow 0.3s ease-out;
  animation: ${fadeAndSlideInUp} 0.3s ease-out backwards;
  cursor: pointer;


  ${media.tablet`
    width: 31.3%;
    margin: 1%;
  `}

  ${media.phone`
    width:  48%;
    height:  140px;
    margin: 1%;
  `}

  ${iterator(10, step => `&:nth-child(${step + 1}) {animation-delay: ${(step + 1) * 0.05}s;}`)};
  
  &:hover {
    box-shadow: 0 4px 24px ${transparentize(0.85, '#000')};
  }

  &:active {
    transform: scale(0.98, 0.98);
  }


  ${ServiceProviderLogo} {
    background-image: url('${props => logos[props.serviceProvider] || otherServiceProviderLogo}');
  }

  ${props =>
    props.isSelected &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 8px;
        display: inline-block;
        width: 12px;
        height: 9px;
        background: center center no-repeat;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzAwYTU5OSIgZD0iTTUuMDkgOS40OTNMMS4yNzQgNS43MyAwIDYuOTg1IDUuMDkgMTIgMTYgMS4yNTQgMTQuNzI3IDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
      }
    `};
`;

export const ServiceProviderSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 845px;
/* 
  &:after {
    width: 210px;
    height: 0;
    content: ''; */
  }
`;
