import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';
import { Modal, ModalDialog, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { updateCustomer, saveQuote } from '../../ducks';
import { CustomerEditorForm } from '../forms';
import customerEditorFormInitialValuesSelector from '../../services/customerEditorFormInitialValuesSelector';

class CustomerEditorModal extends PureComponent {
  onFormSubmit = async ({ contactName, customerEmail, customerPhone, currentSpending, exContractExpireDate, followUpDate }) => {
    const { quoteId, updateCustomer, saveQuote, closeModal, push } = this.props;
    const formattedExtDate = exContractExpireDate
      ? moment(exContractExpireDate, 'MM/DD/YYYY').format('L')
      : moment().format('L');
    const formattedFollowUpDate = followUpDate ? moment(followUpDate, 'MM/DD/YYYY').format('L') : moment().format('L');
    updateCustomer({
      contactName,
      customerEmail,
      customerPhone,
      currentSpending,
      exContractExpireDate: formattedExtDate,
      followUpDate: formattedFollowUpDate,
    });
    closeModal();

    await saveQuote();
    push(`/quote/${quoteId}/summary`);
  };

  render() {
    const { formInitialValues, isSaving, closeModal } = this.props;
    return (
      <Modal isLoading={isSaving}>
        <ModalDialog size="medium" sizeTablet="medium" sizePhone="medium">
          <ModalClose onClick={closeModal}>
            <ModalCloseIcon />
          </ModalClose>

          <ModalTitle>Customer details</ModalTitle>
          <CustomerEditorForm initialValues={formInitialValues} onSubmit={this.onFormSubmit} />
        </ModalDialog>
      </Modal>
    );
  }
}

CustomerEditorModal.propTypes = {
  quoteId: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  formInitialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  saveQuote: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSaving: state.quote.quote.isSaving,
  formInitialValues: customerEditorFormInitialValuesSelector(state.quote.quote),
});

const mapDispatchToProps = {
  updateCustomer,
  saveQuote,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerEditorModal);
