import update from 'immutability-helper';
import { loadOpportunity as doLoadOpportunity } from '../services/opportunity';

// Actions
const START_LOAD = 'opportunities/opportunity/START_LOAD';
const COMPLETE_LOAD = 'opportunities/opportunity/COMPLETE_LOAD';
const FAIL_LOAD = 'opportunities/opportunity/FAIL_LOAD';
const RESET = 'opportunities/opportunity/RESET';

// Initial state
const initialState = {
  isLoading: false,
  opportunity: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          opportunity: action.opportunity,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = opportunity => ({
  type: COMPLETE_LOAD,
  opportunity,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadOpportunity = (quoteId, status) => dispatch => {
  dispatch(startLoad());
  const loadOpportunityPromise = doLoadOpportunity(quoteId, status);
  loadOpportunityPromise.then(opportunity => dispatch(completeLoad(opportunity))).catch(() => dispatch(failLoad()));

  return loadOpportunityPromise;
};

export const resetOpportunity = () => ({
  type: RESET,
});
