import { map, filter, mapKeys, mapValues } from 'lodash-es';
import { FRONT_LOAD, CART } from '../constants';

const transformServiceOptions = serviceOptions => {
  const addresses = mapKeys([serviceOptions], 'zipCode');
  const transformedServiceOptions = map(addresses, ({ zipCode, rules }) => {
    const filteredRules = filter(rules, rule => [FRONT_LOAD, CART].indexOf(rule.service) > -1);

    return {
      zipCode,
      serviceOptions: map(
        filteredRules,
        ({ daysToBeginIncumbent, daysToBegin, material, equipment, service, frequency }) => ({
          serviceType: service,
          wasteType: material,
          containerSize: Number(equipment),
          recurringFrequency: Number(frequency),
          startDateDelayWithSalesContract: daysToBeginIncumbent,
          startDateDelayWithoutSalesContract: daysToBegin,
        }),
      ),
    };
  });

  return mapValues(mapKeys(transformedServiceOptions, 'zipCode'), 'serviceOptions');
};

export default transformServiceOptions;
