import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../core/components';
import { PageLoading } from '../../common/components/styled';
import { loadQuote, resetQuote } from '../ducks';
import ExistingQuoteRouter from './ExistingQuoteRouter';

class ExistingQuoteRouterResolver extends PureComponent {
  componentWillUnmount() {
    const { resetQuote } = this.props;
    resetQuote();
  }

  resolve = async () => {
    const { match, loadQuote } = this.props;
    const { quoteId } = match.params;

    return loadQuote(quoteId);
  };

  render() {
    return (
      <>
        <Resolver
          successComponent={ExistingQuoteRouter}
          loadingComponent={PageLoading}
          resolve={this.resolve}
          redirectOnError="/opportunities"
        />
      </>
    );
  }
}
ExistingQuoteRouterResolver.propTypes = {
  match: RouterPropTypes.match.isRequired,
  loadQuote: PropTypes.func.isRequired,
  resetQuote: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loadQuote, resetQuote };

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(ExistingQuoteRouterResolver),
);
