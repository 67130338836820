import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';
import { PanelSection } from '../../../core/components/styled';
import { createErrorNotification } from '../../../core/services/createNotification';
import { requestPasswordReset, resetRequestPasswordReset } from '../../ducks';
import { DocumentTitle } from '../../../common/components';
import { RequestPasswordResetForm } from '../forms';

class RequestPasswordResetPage extends PureComponent {
  componentWillUnmount() {
    const { resetRequestPasswordReset } = this.props;
    resetRequestPasswordReset();
  }

  onFormSubmit = ({ email }) => {
    const { requestPasswordReset, push } = this.props;
    requestPasswordReset(email)
      .then(() => {
        push('/account/forgot-password-email-sent');
      })
      .catch(() => {
        const { reset } = this.props;
        createErrorNotification('Email does not exist.');
        reset('requestPasswordReset');
      });
  };

  render() {
    const { isRequestingPasswordReset } = this.props;
    return (
      <>
        <DocumentTitle>Forgot your password?</DocumentTitle>
        <PanelSection isLoading={isRequestingPasswordReset}>
          <RequestPasswordResetForm onSubmit={this.onFormSubmit} />
        </PanelSection>
      </>
    );
  }
}

RequestPasswordResetPage.propTypes = {
  isRequestingPasswordReset: PropTypes.bool.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  resetRequestPasswordReset: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const mapStateToProps = state => state.account.requestPasswordReset;

const mapDispatchToProps = {
  requestPasswordReset,
  resetRequestPasswordReset,
  push,
  reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestPasswordResetPage);
