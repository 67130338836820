import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import LocationEditorPage from './LocationEditorPage';

class LocationEditorPageResolver extends PureComponent {
  resolve = async () => {
    const { location } = this.props;
    return location ? Promise.resolve() : Promise.reject(new Error('Invalid location id.'));
  };

  render() {
    return (
      <>
        <DocumentTitle>Location details</DocumentTitle>
        <Resolver
          successComponent={LocationEditorPage}
          loadingComponent={PageLoading}
          resolve={this.resolve}
          redirectOnError="/opportunities"
        />
      </>
    );
  }
}
LocationEditorPageResolver.propTypes = {
  location: PropTypes.object,
};

LocationEditorPageResolver.defaultProps = {
  location: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  quoteId: ownProps.match.params.quoteId,
  location: state.quote.quote.locations[ownProps.match.params.locationId],
});

export default withRouter(connect(mapStateToProps)(LocationEditorPageResolver));
