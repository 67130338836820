import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { isRequired } from '../../../utils/services/validator';

import ServiceProviderSelector from '../ServiceProviderSelector';

const ServiceProviderForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <Field name="serviceProvider" component={ServiceProviderSelector} validate={[isRequired]} />
  </form>
);

ServiceProviderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'serviceProvider',
})(ServiceProviderForm);
