import update from 'immutability-helper';
import uuid from 'uuid';
import { map, filter, reduce, pick, pickBy, size, has, find, findKey, sumBy, identity } from 'lodash-es';
import { createSelector } from 'reselect';
import { FRONT_LOAD, CART } from '../../common/constants';
import { CONTACTED, QUOTED, SOLD, DISQUALIFIED } from '../constants';
import {
  createQuote,
  updateQuote,
  loadQuote as doLoadQuote,
  sendShoppingCart as doSendShoppingCart,
  signWithCustomer as doSignWithCustomer,
} from '../services/quote';

// Actions
const RECALCULATE_STATUS = 'quote/quote/RECALCULATE_STATUS';
const UPDATE_CUSTOMER = 'quote/quote/UPDATE_CUSTOMER';
const ADD_LOCATION = 'quote/quote/ADD_LOCATION';
const UPDATE_LOCATION = 'quote/quote/UPDATE_LOCATION';
const DELETE_LOCATION = 'quote/quote/DELETE_LOCATION';
const ADD_SERVICE = 'quote/quote/ADD_SERVICE';
const UPDATE_SERVICE = 'quote/quote/UPDATE_SERVICE';
const UPDATE_SERVICE_PRICE = 'quote/quote/UPDATE_SERVICE_PRICE';
const DELETE_SERVICE = 'quote/quote/DELETE_SERVICE';
const START_SAVE = 'quote/quote/START_SAVE';
const COMPLETE_SAVE = 'quote/quote/COMPLETE_SAVE';
const FAIL_SAVE = 'quote/quote/FAIL_SAVE';
const START_LOAD = 'quote/quote/START_LOAD';
const COMPLETE_LOAD = 'quote/quote/COMPLETE_LOAD';
const FAIL_LOAD = 'quote/quote/FAIL_LOAD';
const START_CREATE_SHOPPING_CART = 'quote/quote/START_CREATE_SHOPPING_CART';
const COMPLETE_CREATE_SHOPPING_CART = 'quote/quote/COMPLETE_CREATE_SHOPPING_CART';
const FAIL_CREATE_SHOPPING_CART = 'quote/quote/FAIL_CREATE_SHOPPING_CART';
const RESET = 'quote/quote/RESET';

// Initial state
const initialState = {
  isSaving: false,
  isSaveFailed: false,
  isLoading: false,
  isLoadFailed: false,
  isCreatingShoppingCart: false,
  isShoppingCartCreationFailed: false,
  isNew: true,
  id: undefined,
  status: CONTACTED,
  contactName: undefined,
  customerEmail: undefined,
  customerPhone: undefined,
  currentSpending: undefined,
  exContractExpireDate: undefined,
  followUpDate: undefined,
  locations: {},
};

// Helpers
const getLocationIdByServiceId = (quoteState, serviceId) =>
  findKey(quoteState.locations, location => has(location.services, serviceId));

const getQuoteStatus = ({ status, locations, currentSpending }) => {
  if (status === SOLD) return status;

  const serviceCount = sumBy(map(locations), location => size(location.services));

  const qualifiedServiceCount = sumBy(map(locations), location =>
    size(pickBy(location.services, service => !service.isDisqualified)),
  );

  const billableServiceCount = size(filter(locations, location => !location.isNewService));

  if (serviceCount && !qualifiedServiceCount) return DISQUALIFIED;
  if (qualifiedServiceCount && (!!currentSpending || !billableServiceCount)) return QUOTED;

  return CONTACTED;
};

// Contstants
const FILLABLE_QUOTE_PROPERTIES = [
  'id',
  'status',
  'contactName',
  'customerEmail',
  'customerPhone',
  'savingsPercent',
  'currentSpending',
  'exContractExpireDate',
  'followUpDate',
  'locations',
];
const FILLABLE_LOCATION_PROPERTIES = [
  'businessName',
  'businessTypeId',
  'serviceProviderId',
  'disqualifiedServiceProvider',
  'isDisqualified',
  'disqualifiedReason',
  'address',
];

const FILLABLE_CART_SERVICE_PROPERTIES = [
  'serviceType',
  'incumbentRebateProgram',
  'incumbentRebateMonthly',
  'numberOfContainers',
  'containerSize',
  'wasteType',
  'recurringFrequency',
  'recognizedProgram',
  'price',
  'note',
];

const FILLABLE_FRONT_LOAD_SERVICE_PROPERTIES = [...FILLABLE_CART_SERVICE_PROPERTIES, 'hasLockbar', 'hasCasters'];

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECALCULATE_STATUS: {
      return update(state, {
        status: { $set: getQuoteStatus(state) },
      });
    }

    case UPDATE_CUSTOMER:
      return update(state, {
        $merge: {
          contactName: action.contactName,
          customerEmail: action.customerEmail,
          customerPhone: action.customerPhone,
          currentSpending: action.currentSpending,
          exContractExpireDate: action.exContractExpireDate,
          followUpDate: action.followUpDate,
        },
      });

    case ADD_LOCATION:
      return update(state, {
        locations: {
          [action.locationId]: {
            $set: {
              ...pick(action.location, FILLABLE_LOCATION_PROPERTIES),
              id: action.locationId,
              services: {},
            },
          },
        },
      });

    case UPDATE_LOCATION: {
      const { isDisqualified } = action.location;
      const extraProperties = isDisqualified ? { services: {} } : undefined;

      return update(state, {
        locations: {
          [action.locationId]: {
            $merge: {
              ...pick(action.location, FILLABLE_LOCATION_PROPERTIES),
              ...extraProperties,
            },
          },
        },
      });
    }

    case DELETE_LOCATION:
      return update(state, {
        locations: {
          $unset: [action.locationId],
        },
      });

    case ADD_SERVICE: {
      const serviceProperties =
        action.service.serviceType === FRONT_LOAD
          ? FILLABLE_FRONT_LOAD_SERVICE_PROPERTIES
          : FILLABLE_CART_SERVICE_PROPERTIES;

      return update(state, {
        locations: {
          [action.locationId]: {
            services: {
              [action.serviceId]: {
                $set: {
                  ...pick(action.service, serviceProperties),
                  id: action.serviceId,
                  isDisqualified: false,
                },
              },
            },
          },
        },
      });
    }

    case UPDATE_SERVICE: {
      const locationId = getLocationIdByServiceId(state, action.serviceId);
      const serviceProperties =
        action.service.serviceType === FRONT_LOAD
          ? FILLABLE_FRONT_LOAD_SERVICE_PROPERTIES
          : FILLABLE_CART_SERVICE_PROPERTIES;

      const unsetProperties = action.service.serviceType === CART ? ['hasCasters', 'hasLockbar', 'price'] : ['price'];
      return update(state, {
        locations: {
          [locationId]: {
            services: {
              [action.serviceId]: {
                $merge: {
                  ...pick(action.service, serviceProperties),
                  isDisqualified: false,
                  disqualifiedReason: undefined,
                },
                $unset: unsetProperties,
              },
            },
          },
        },
      });
    }

    case UPDATE_SERVICE_PRICE: {
      const locationId = getLocationIdByServiceId(state, action.serviceId);

      return update(state, {
        locations: {
          [locationId]: {
            services: {
              [action.serviceId]: {
                $merge: {
                  price: {
                    pickup: action.price,
                  },
                },
              },
            },
          },
        },
      });
    }

    case DELETE_SERVICE: {
      const locationId = getLocationIdByServiceId(state, action.serviceId);

      return update(state, {
        locations: {
          [locationId]: {
            services: {
              $unset: [action.serviceId],
            },
          },
        },
      });
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          ...pick(action.quote, FILLABLE_QUOTE_PROPERTIES),
          isSaving: false,
          isSaveFailed: false,
          isNew: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          isSaveFailed: true,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          ...pick(action.quote, FILLABLE_QUOTE_PROPERTIES),
          isLoading: false,
          isLoadFailed: false,
          isNew: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isLoadFailed: true,
        },
      });

    case START_CREATE_SHOPPING_CART:
      return update(state, {
        $merge: {
          isCreatingShoppingCart: true,
        },
      });

    case COMPLETE_CREATE_SHOPPING_CART:
      return update(state, {
        $merge: {
          isCreatingShoppingCart: false,
          isShoppingCartCreationFailed: false,
        },
      });

    case FAIL_CREATE_SHOPPING_CART:
      return update(state, {
        $merge: {
          isCreatingShoppingCart: false,
          isShoppingCartCreationFailed: true,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
export const recalculateStatus = () => ({
  type: RECALCULATE_STATUS,
});

export const updateCustomer = ({
  contactName,
  customerEmail,
  customerPhone,
  currentSpending,
  exContractExpireDate,
  followUpDate,
}) => ({
  type: UPDATE_CUSTOMER,
  contactName,
  customerEmail,
  customerPhone,
  currentSpending,
  exContractExpireDate,
  followUpDate,
});

export const addLocation = (locationId, location) => ({
  type: ADD_LOCATION,
  locationId,
  location,
});

export const updateLocation = (locationId, location) => ({
  type: UPDATE_LOCATION,
  locationId,
  location,
});

export const deleteLocation = locationId => ({
  type: DELETE_LOCATION,
  locationId,
});

export const addService = (locationId, serviceId, service) => ({
  type: ADD_SERVICE,
  locationId,
  serviceId,
  service,
});

export const updateService = (serviceId, service) => ({
  type: UPDATE_SERVICE,
  serviceId,
  service,
});

export const updateServicePrice = (serviceId, price) => ({
  type: UPDATE_SERVICE_PRICE,
  serviceId,
  price,
});

export const deleteService = serviceId => ({
  type: DELETE_SERVICE,
  serviceId,
});

const startSaveQuote = () => ({
  type: START_SAVE,
});

const completeSaveQuote = quote => ({
  type: COMPLETE_SAVE,
  quote,
});

const failSaveQuote = () => ({
  type: FAIL_SAVE,
});

const startLoadQuote = () => ({
  type: START_LOAD,
});

const completeLoadQuote = quote => ({
  type: COMPLETE_LOAD,
  quote,
});

const failLoadQuote = () => ({
  type: FAIL_LOAD,
});

const startCreateShoppingCart = () => ({
  type: START_CREATE_SHOPPING_CART,
});

const completeCreateShoppingCart = () => ({
  type: COMPLETE_CREATE_SHOPPING_CART,
});

const failCreateShoppingCart = () => ({
  type: FAIL_CREATE_SHOPPING_CART,
});

export const saveQuote = () => (dispatch, getState) => {
  dispatch(recalculateStatus());

  const state = getState();
  const { quote } = state.quote;
  dispatch(startSaveQuote());
  const saveQuotePromise = quote.isNew ? createQuote({ ...quote, id: uuid() }) : updateQuote(quote);
  saveQuotePromise.then(quote => dispatch(completeSaveQuote(quote))).catch(() => dispatch(failSaveQuote()));
  return saveQuotePromise;
};

export const loadQuote = quoteId => dispatch => {
  dispatch(startLoadQuote());
  const loadQuotePromise = doLoadQuote(quoteId);
  loadQuotePromise.then(quote => dispatch(completeLoadQuote(quote))).catch(() => dispatch(failLoadQuote()));
  return loadQuotePromise;
};

export const sendShoppingCart = quoteId => dispatch => {
  dispatch(startCreateShoppingCart());
  const sendShoppingCartPromise = doSendShoppingCart(quoteId);

  sendShoppingCartPromise
    .then(() => dispatch(completeCreateShoppingCart()))
    .catch(() => dispatch(failCreateShoppingCart()));

  return sendShoppingCartPromise;
};

export const signWithCustomer = quoteId => dispatch => {
  dispatch(startCreateShoppingCart());
  const signWithCustomerPromise = doSignWithCustomer(quoteId);

  signWithCustomerPromise
    .then(() => dispatch(completeCreateShoppingCart()))
    .catch(() => dispatch(failCreateShoppingCart()));

  return signWithCustomerPromise;
};

export const resetQuote = () => ({
  type: RESET,
});

// Selectors
const getLocationByServiceId = (quoteState, serviceId) =>
  find(quoteState.locations, location => has(location.services, serviceId));

export const locationByServiceIdSelector = createSelector(
  getLocationByServiceId,
  identity,
);

const getServiceByServiceId = (quoteState, serviceId) => {
  const location = getLocationByServiceId(quoteState, serviceId);
  return location && location.services[serviceId];
};

export const serviceByIdSelector = createSelector(
  getServiceByServiceId,
  identity,
);

const getLocations = (quoteState, { mustBeQualified, mustHaveAddress } = {}) =>
  filter(
    map(quoteState.locations),
    location => (!mustBeQualified || !location.isDisqualified) && (!mustHaveAddress || location.address),
  );

export const locationsSelector = createSelector(
  getLocations,
  identity,
);

const getLocationsWithServices = (quoteState, { mustBeQualified, mustHaveAddress } = {}) => {
  const locations = getLocations(quoteState, { mustBeQualified, mustHaveAddress });

  return map(locations, location => ({
    ...location,
    services: map(location.services),
  }));
};

export const locationsWithServicesSelector = createSelector(
  getLocationsWithServices,
  identity,
);

const countLocations = quoteState => size(quoteState.locations);

export const locationCountSelector = createSelector(
  countLocations,
  identity,
);

const countServices = quoteState =>
  reduce(quoteState.locations, (count, location) => count + size(location.services), 0);

export const serviceCountSelector = createSelector(
  countServices,
  identity,
);

const countQualifiedServices = quoteState =>
  reduce(
    quoteState.locations,
    (count, location) => count + size(pickBy(location.services, service => !service.isDisqualified)),
    0,
  );

export const qualifiedServiceCountSelector = createSelector(
  countQualifiedServices,
  identity,
);

const countBillableLocations = quoteState => size(filter(quoteState.locations, location => !location.isNewService));

export const billableLocationsCountSelector = createSelector(
  countBillableLocations,
  identity,
);
