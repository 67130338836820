import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from '../../core/components';
import {
  ServiceList as ServiceListContainer,
  ServiceListHeader,
  ServiceListLocation,
  ServiceListHeaderActions,
  ServiceListLocationIndex,
  ServiceListLocationAddress,
  ServiceListLocationError,
} from './styled';
import ServiceCard from './ServiceCard';

class ServiceList extends PureComponent {
  constructor(props) {
    super(props);
    const editMenuItem = { label: 'Edit', color: 'primary', onClick: this.editLocation };
    const addServiceMenuItem = { label: 'Add Service', color: 'primary', onClick: this.addService };
    const deleteMenuItem = { label: 'Delete', color: 'alert', onClick: this.deleteLocation };

    this.dropdownMenuItems = props.location.isDisqualified
      ? [editMenuItem, deleteMenuItem]
      : [editMenuItem, addServiceMenuItem, deleteMenuItem];
  }

  editLocation = () => {
    const { editLocation, location } = this.props;
    editLocation(location.id);
  };

  deleteLocation = () => {
    const { deleteLocation, location } = this.props;
    deleteLocation(location.id);
  };

  addService = () => {
    const { addService, location } = this.props;
    addService(location.id);
  };

  render() {
    const {
      locationIndex,
      location,
      showPrice,
      smallCard,
      isPartnerSuperAdmin,
      selectedLocationId,
      selectedServiceId,
      editService,
      deleteService,
    } = this.props;

    return (
      <ServiceListContainer>
        <ServiceListHeader>
          <ServiceListLocation isSelected={location.id === selectedLocationId} isDisqualified={location.isDisqualified}>
            <ServiceListLocationIndex>{locationIndex + 1}</ServiceListLocationIndex>
            <ServiceListLocationAddress>
              {location.address.line1}

              {location.isDisqualified && (
                <ServiceListLocationError>{location.disqualifiedReason}</ServiceListLocationError>
              )}
            </ServiceListLocationAddress>
          </ServiceListLocation>

          {!isPartnerSuperAdmin && (
            <ServiceListHeaderActions>
              <DropdownMenu items={this.dropdownMenuItems} />
            </ServiceListHeaderActions>
          )}
        </ServiceListHeader>

        {location.services.map(service => (
          <ServiceCard
            key={service.id}
            showPrice={showPrice}
            smallCard={smallCard}
            isSelected={service.id === selectedServiceId}
            editService={editService}
            deleteService={deleteService}
            isPartnerSuperAdmin={isPartnerSuperAdmin}
            {...service}
          />
        ))}
      </ServiceListContainer>
    );
  }
}

ServiceList.propTypes = {
  locationIndex: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  showPrice: PropTypes.bool,
  smallCard: PropTypes.bool,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  selectedLocationId: PropTypes.string,
  selectedServiceId: PropTypes.string,
  editLocation: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  editService: PropTypes.func.isRequired,
  deleteService: PropTypes.func.isRequired,
};

ServiceList.defaultProps = {
  showPrice: undefined,
  smallCard: false,
  selectedLocationId: undefined,
  selectedServiceId: undefined,
};

export default ServiceList;
