import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { get, identity } from 'lodash-es';

// Actions
const PUSH = 'core/lastLocations/PUSH';

// Initial state
const initialState = {};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH:
      return update(state, {
        [action.pathname]: { $set: action.url },
      });

    default:
      return state;
  }
};

// Action creators
export const addLastLocation = (pathname, url) => ({
  type: PUSH,
  pathname,
  url,
});

// Selectors
const getLastUrl = (coreState, pathname) => get(coreState.lastLocations, pathname, pathname);

export const getLastUrlSelector = createSelector(
  getLastUrl,
  identity,
);
