import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { map, size } from 'lodash-es';
import { Panel, Message, TableRow, TableCell, Text } from '../../../core/components/styled';
import { Table, Pagination } from '../../../core/components';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { localDate } from '../../../utils/services/formatter';
import { SuperAdminGuard } from '../../../account/components';
import { isPartnerSuperAdminSelector } from '../../../account/ducks';
import { CONTACTED } from '../../../quote/constants';
import { loadOpportunities, resetOpportunities } from '../../ducks';

const LIMIT_PER_PAGE = 20;

const ContactedOpportunitiesTableRow = ({
  locations,
  partnerName,
  partnerUserFirstName,
  partnerUserLastName,
  lastEditDate,
  uid,
  tableCellWidths,
  push,
}) => (
  <TableRow onClick={() => push(`/quote/${uid}/summary`)}>
    <SuperAdminGuard>
      <TableCell width={tableCellWidths[0]}>{partnerName}</TableCell>
    </SuperAdminGuard>
    <TableCell width={tableCellWidths[1]}>
      {partnerUserFirstName} {partnerUserLastName}
    </TableCell>
    <TableCell width={tableCellWidths[1]}>
      {map(locations, ({ businessName, uid }) => (
        <Text block key={uid}>
          {businessName}
        </Text>
      ))}
    </TableCell>
    <TableCell width={tableCellWidths[3]} align="center">
      {size(locations)}
    </TableCell>
    <TableCell width={tableCellWidths[4]} align="center">
      {map(locations, ({ services, uid }) => (
        <Text block key={uid}>
          {size(services)}
        </Text>
      ))}
    </TableCell>
    <TableCell width={tableCellWidths[5]}>{localDate(lastEditDate)}</TableCell>
  </TableRow>
);
ContactedOpportunitiesTableRow.propTypes = {
  uid: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  partnerName: PropTypes.string,
  partnerUserFirstName: PropTypes.string,
  partnerUserLastName: PropTypes.string,
  lastEditDate: PropTypes.string.isRequired,
  tableCellWidths: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
};

ContactedOpportunitiesTableRow.defaultProps = {
  partnerName: undefined,
  partnerUserFirstName: undefined,
  partnerUserLastName: undefined,
};

class ContactedOpportunitiesSection extends PureComponent {
  componentDidMount() {
    const {
      history,
      loadOpportunities,
      location: { pathname },
    } = this.props;
    this.unlistenHistory = history.listen(location => {
      if (pathname === location.pathname) {
        const { page, limit, sortedBy, sortOrder } = getQueryParams(location.search);
        loadOpportunities(CONTACTED, page, limit, sortedBy, sortOrder);
      }
    });
  }

  componentWillUnmount() {
    const { resetOpportunities } = this.props;
    this.unlistenHistory();
    resetOpportunities();
  }

  onSortOrderChange = (sortedBy, sortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  render() {
    const {
      isLoading,
      opportunities,
      total,
      location: { search },
      push,
      isPartnerSuperAdmin,
    } = this.props;
    const { sortOrder, sortedBy } = getQueryParams(search);

    const tableCellWidths = isPartnerSuperAdmin
      ? ['20%', '20%', '20%', '15%', '15%', '10%']
      : [undefined, '30%', '25%', '15%', '15%', '15%'];

    const contactedOpportunitiesTableCells = [
      { name: 'partnerName', label: 'Partner name', width: tableCellWidths[0], sortable: true },
      { name: 'userName', label: 'User name', width: tableCellWidths[1], sortable: true },
      { name: 'businessName', label: 'Business name', width: tableCellWidths[2] },
      { name: 'numberOfLocations', label: 'No. of locations', width: tableCellWidths[3] },
      { name: 'numberOfServices', label: 'No. of services', width: tableCellWidths[4] },
      { name: 'lastEditDate', label: 'Last Updated', width: tableCellWidths[5], sortable: true },
    ];

    if (!isPartnerSuperAdmin) {
      contactedOpportunitiesTableCells.splice(0, 1);
    }

    return (
      <Panel padding="no no xxSmall" isLoading={isLoading}>
        {total > 0 && (
          <Table
            cells={contactedOpportunitiesTableCells}
            rows={opportunities}
            rowComponent={ContactedOpportunitiesTableRow}
            rowProps={{ tableCellWidths, push }}
            sort={this.onSortOrderChange}
            sortOrder={sortOrder}
            sortedBy={sortedBy}
            withClickableRows
          />
        )}
        {total === 0 && <Message padding="sMedium">There are no contacted opportunities</Message>}

        {total > LIMIT_PER_PAGE && (
          <Pagination totalResults={total} limitPerPage={LIMIT_PER_PAGE} maxPaginationItems={10} />
        )}
      </Panel>
    );
  }
}

ContactedOpportunitiesSection.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  opportunities: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loadOpportunities: PropTypes.func.isRequired,
  resetOpportunities: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: RouterPropTypes.location.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.opportunities.opportunities.isLoading,
  opportunities: state.opportunities.opportunities.opportunities,
  total: state.opportunities.opportunities.total,
  isPartnerSuperAdmin: isPartnerSuperAdminSelector(state.account.login),
});

const mapDispatchToProps = { loadOpportunities, resetOpportunities, push };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactedOpportunitiesSection),
);
