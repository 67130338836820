import styled from 'styled-components';
import { sizeMapper } from '../../../utils/styles';

export const RubiconLogoInline = styled.div`
  width: 220px;
  height: 38px;
  margin: ${props => sizeMapper(props.margin, 'no')};
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYwIiBoZWlnaHQ9IjUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcv
MjAwMC9zdmciPjxnIGZpbGwtcnVsZT0ibm9uemVybyIgZmlsbD0ibm9uZSI+PHBhdGggZD0i
TTgyLjI1MSAxOC4zMjZjMC01Ljg0Ny00LjUwNi02LjY0Ny04Ljg2NC02LjY0N0g2MC4zNjNW
MzMuNTdoNC42NTV2LTguMzk3aDYuMTlsNi4xNCA4LjM5N2g1Ljc0NWwtNi41MzctOC43NDdj
Mi45MjItLjM1IDUuNjk1LTIuNDQ5IDUuNjk1LTYuNDk4em0tMTcuMjMzLTIuNjk5aDcuNzI1
YzMuNjY1IDAgNC44NTMuNjUgNC44NTMgMi43NSAwIDIuMDk5LTEuMTg4IDIuNzk4LTQuODUz
IDIuNzk4aC03LjcyNXYtNS41NDh6TTEwOS45ODMgMjMuODI1YzAgMy4wNDgtLjc5MyA2LjE0
Ny02Ljg4NCA2LjE0Ny02LjA0MSAwLTYuODgzLTMuMDk5LTYuODgzLTYuMTQ3VjExLjYyOWgt
NC42NTV2MTIuNDk1YzAgNi41OTggMy44NjMgOS45NDcgMTEuNDg5IDkuOTQ3IDcuNjI2IDAg
MTEuNDg5LTMuMzQ5IDExLjQ4OS05Ljk0N1YxMS42M2gtNC42NTV2MTIuMTk2aC4wOTl6TTE0
NC40IDIxLjgyNWMxLjEzOS0uOCAyLjE3OC0xLjkgMi4xNzgtNC4zOTggMC0zLjg0OS0yLjgy
Mi01Ljc0OC04LjU2Ny01Ljc0OGgtMTMuMDI0VjMzLjU3aDEzLjgxN2M1LjQ0NyAwIDguNjE2
LTIuMzUgOC42MTYtNi40NDggMC0yLjU0OS0xLjQ4NS00LjQ0OC0zLjAyLTUuMjk4em0tNi4w
OTEtMS43aC04LjY2N3YtNC40OThoOC42NjdjMy4wMiAwIDMuNjY0LjkgMy42NjQgMi4yNS0u
MDUgMS4zNS0uNjQ0IDIuMjQ5LTMuNjY0IDIuMjQ5em0tOC42NjcgMy45NWg4Ljc2NmMzLjAy
IDAgNC4zNTcuODUgNC4zNTcgMi42OTggMCAyLTEuMjM4IDIuOC00LjM1NyAyLjhoLTguNzY2
di01LjQ5OXpNMTU2LjUzMiAxMS42NzloNC42NTVWMzMuNTdoLTQuNjU1ek0xODkuNDE0IDI1
LjYyNGMtLjM5NiAxLjUtMi4zMjggNC4zNDgtNy4xMzEgNC4zNDgtNC43NTQgMC03LjgyNS0y
Ljg0OS03LjgyNS03LjI0NyAwLTQuODQ4IDIuODczLTcuNDk3IDguMDcyLTcuNDk3IDQuNDU3
IDAgNi4yOSAyLjU5OSA2LjU4NyAzLjU5OGwuMDUuMmg0LjY1NGwtLjA5OS0uNDVjLTEuNjg0
LTYuMTQ3LTcuMTMxLTcuNDQ3LTExLjM5LTcuNDQ3LTcuODc0IDAtMTIuNTc4IDQuMTQ5LTEy
LjU3OCAxMS4xNDYgMCA3LjI5NyA0LjcwNCAxMS44NDYgMTIuMjMyIDExLjg0NiAzLjk2MSAw
IDEwLjg0NS0xLjEgMTIuMDgzLTguMzQ3bC4wNS0uM2gtNC42NTZsLS4wNS4xNXpNMjE0LjU3
IDExLjEyOWMtOC4wNzIgMC0xMi44NzUgNC4zNDgtMTIuODc1IDExLjY0NiAwIDEuOS42NDQg
MTEuMzQ2IDEyLjg3NSAxMS4zNDYgMTIuMjMyIDAgMTIuODc1LTkuNDQ3IDEyLjg3NS0xMS4z
NDYgMC03LjI5OC00LjgwMy0xMS42NDYtMTIuODc1LTExLjY0NnptOC4yMiAxMS4zOTZjMCA2
Ljc0OC01Ljc0NCA3LjQ0Ny04LjIyIDcuNDQ3LTIuNDc2IDAtOC4yMi0uNzUtOC4yMi03LjQ0
NyAwLTQuNTk4IDMuMDctNy4yOTcgOC4yMi03LjI5N3M4LjIyIDIuNzQ5IDguMjIgNy4yOTd6
TTI1NC40ODQgMTEuNjc5djE1LjA0NWwtMTMuODE3LTE1LjA0NWgtNC43NTRWMzMuNTdoNC40
NTdWMTcuOTI3bDE0LjQxIDE1LjY0NGg0LjE2VjExLjY4eiIgZmlsbD0iIzFBMUExQSIvPjxn
IGZpbGw9IiMwMEE2OUEiPjxwYXRoIGQ9Ik00OC4zOCAzMS4wNzJjLS43NDQtMi44OTktMi42
MjUtNS4yOTgtNS4xNS02Ljc5OGwtMTMuNTctNy45NDctMS4yODctLjc1LTIuNzczLTEuNTk5
djguODQ3bDEzLjg2NiA4LjA5N2MuNzkyLjQ1IDEuMzg2IDEuMjUgMS42MzQgMi4xNS4yNDcu
ODk5LjA5OSAxLjg0OS0uMzQ3IDIuNjk4LS40NDYuOC0xLjIzOCAxLjQtMi4xMyAxLjY1LS41
OTQuMTUtMS4yODcuMTUtMS44ODEgMGwtLjM0Ny0uMS03LjU3NiA0LjQ0OCAzLjM2NyAxLjk1
YzEuNjg0IDEgMy42MTUgMS40OTkgNS41NDYgMS40OTkuOTkgMCAxLjkzMi0uMTUgMi44NzMt
LjQgMi44NzItLjc1IDUuMjQ5LTIuNjQ5IDYuNzM0LTUuMTk4IDEuNDM2LTIuNjUgMS44MzMt
NS42OTggMS4wNC04LjU0N3oiLz48cGF0aCBkPSJNMjEuMzQxIDI3LjUyM1YxMS4zM2MwLTEu
OTUgMS41ODUtMy41NDkgMy41MTYtMy41NDkgMS41ODUgMCAyLjk3MSAxLjEgMy4zNjcgMi42
bC4xLjM1IDcuNjI2IDQuNDQ4di0zLjg0OUMzNS45NSA1LjE4MSAzMC45OTguMTMzIDI0Ljg1
Ny4xMzNjLTYuMTQgMC0xMS4wOTMgNC45OTgtMTEuMDkzIDExLjE5NnYyMC41OTNsNy41Nzct
NC4zOTl6Ii8+PHBhdGggZD0iTTI3LjU4IDI4Ljc3M0wxMy41NjcgMzYuOTJjLS41NDQuMy0x
LjEzOS40NS0xLjczMy40NS0xLjIzOCAwLTIuNDI2LS43LTMuMDItMS43NWEzLjYyNCAzLjYy
NCAwIDAgMS0uMzQ3LTIuNjk5Yy4xNDgtLjYuNDk1LTEuMi45NC0xLjY1bC4yNDgtLjI0OXYt
OC44NDdsLTMuMzY3IDEuOTVjLTIuNTc1IDEuNDk5LTQuNDA4IDMuODk4LTUuMTUgNi43OTct
Ljc0MyAyLjg5OS0uMzk2IDUuODk4IDEuMDkgOC40OTcgMS45OCAzLjQ0OSA1LjY0NCA1LjU5
OCA5LjYwNiA1LjU5OCAxLjkzMSAwIDMuODYzLS41IDUuNTQ2LTEuNWwxNy43NzgtMTAuMzQ2
LTcuNTc2LTQuMzk4eiIvPjwvZz48cGF0aCBkPSJNMTk0LjMzNyA0Ni41NjZWNTBoLTEuNjA2
di05LjI0MmgzLjUzNmE0LjMyIDQuMzIgMCAwIDEgMS40MzEuMjE5Yy40MTcuMTQ2Ljc3LjM1
IDEuMDU3LjYxMi4yODguMjYzLjUwNy41NzQuNjU3LjkzMy4xNS4zNi4yMjUuNzUyLjIyNSAx
LjE3NSAwIC40NDgtLjA3NS44NS0uMjI1IDEuMjA2YTIuMzkgMi4zOSAwIDAgMS0uNjU3Ljkw
MWMtLjI4OC4yNDYtLjY0LjQzNC0xLjA1Ny41NjVhNC43NjggNC43NjggMCAwIDEtMS40MzEu
MTk3aC0xLjkzem0wLTEuMjg5aDEuOTNjLjMwOSAwIC41NzQtLjAzOC43OTYtLjExNGExLjQ1
IDEuNDUgMCAwIDAgLjU1LS4zMjRjLjE0My0uMTQuMjQ4LS4zMDUuMzE0LS40OTguMDY1LS4x
OTIuMDk4LS40MDMuMDk4LS42MzIgMC0uMjE1LS4wMzMtLjQyMy0uMDk4LS42MjJhMS40MjMg
MS40MjMgMCAwIDAtLjMxNS0uNTMgMS41MjkgMS41MjkgMCAwIDAtLjU0OS0uMzY4IDIuMDk4
IDIuMDk4IDAgMCAwLS43OTYtLjEzNmgtMS45M3YzLjIyNHptMTIuNzQ2IDIuNTcxaC0zLjU4
TDIwMi43NTQgNTBoLTEuNjY5bDMuNDkxLTkuMjQyaDEuNDQxTDIwOS41MTUgNTBoLTEuNjc2
bC0uNzU2LTIuMTUyem0tMy4xMjktMS4yOTVoMi42NzlsLTEuMzQtMy44MzQtMS4zMzkgMy44
MzR6bTEyLjAwNC0uMTE0aC0xLjc5VjUwaC0xLjYwNnYtOS4yNDJoMy4yNWMuNTI5IDAgMSAu
MDYgMS40MTYuMTguNDE0LjEyMS43NjQuMyAxLjA1LjUzNC4yODYuMjM1LjUwMy41MjYuNjUu
ODczLjE0OS4zNDcuMjIzLjc0OS4yMjMgMS4yMDYgMCAuNjE4LS4xNSAxLjEzNS0uNDQ4IDEu
NTUyLS4yOTguNDE3LS43MTQuNzM3LTEuMjQ3Ljk2MWwyLjA3NiAzLjg1M1Y1MGgtMS43Mmwt
MS44NTQtMy41NjF6bS0xLjc5LTEuMjg5aDEuNjVjLjI4OCAwIC41NC0uMDM4Ljc1Ni0uMTE0
LjIxNi0uMDc2LjM5NS0uMTgyLjU0LS4zMTcuMTQzLS4xMzYuMjUxLS4yOTYuMzIzLS40ODMu
MDcyLS4xODYuMTA4LS4zOTEuMTA4LS42MTUgMC0uMjM3LS4wMzQtLjQ1Mi0uMTAyLS42NDVh
MS4yOCAxLjI4IDAgMCAwLS4zMS0uNDk1IDEuMzg2IDEuMzg2IDAgMCAwLS41MzctLjMxNyAy
LjQ0OCAyLjQ0OCAwIDAgMC0uNzg0LS4xMTFoLTEuNjQ0djMuMDk3em0xNC44NjctMy4wOTdo
LTIuODgyVjUwaC0xLjU5M3YtNy45NDdoLTIuODU3di0xLjI5NWg3LjMzMnYxLjI5NXpNMjM5
LjU3OSA1MGgtMS42MDZsLTQuMTItNi41NTdWNTBoLTEuNjA2di05LjI0MmgxLjYwNmw0LjEz
MiA2LjU4MnYtNi41ODJoMS41OTRWNTB6bTkuMy00LjEyNmgtMy43OTZ2Mi44NDRoNC40MzdW
NTBoLTYuMDQzdi05LjI0Mmg1Ljk5OHYxLjI5NWgtNC4zOTJ2Mi41NTFoMy43OTV2MS4yN3pt
Ny4zNDQuNTY1aC0xLjc5VjUwaC0xLjYwNnYtOS4yNDJoMy4yNWMuNTMgMCAxLjAwMS4wNiAx
LjQxNi4xOC40MTQuMTIxLjc2NS4zIDEuMDUuNTM0LjI4Ni4yMzUuNTAzLjUyNi42NS44NzMu
MTQ5LjM0Ny4yMjMuNzQ5LjIyMyAxLjIwNiAwIC42MTgtLjE1IDEuMTM1LS40NDcgMS41NTIt
LjI5OS40MTctLjcxNS43MzctMS4yNDguOTYxbDIuMDc2IDMuODUzVjUwaC0xLjcybC0xLjg1
NC0zLjU2MXptLTEuNzktMS4yODloMS42NWMuMjg4IDAgLjU0LS4wMzguNzU2LS4xMTQuMjE2
LS4wNzYuMzk2LS4xODIuNTQtLjMxNy4xNDMtLjEzNi4yNTEtLjI5Ni4zMjMtLjQ4My4wNzIt
LjE4Ni4xMDgtLjM5MS4xMDgtLjYxNSAwLS4yMzctLjAzNC0uNDUyLS4xMDItLjY0NWExLjI4
IDEuMjggMCAwIDAtLjMxLS40OTUgMS4zODYgMS4zODYgMCAwIDAtLjUzNy0uMzE3IDIuNDQ4
IDIuNDQ4IDAgMCAwLS43ODQtLjExMWgtMS42NDR2My4wOTd6IiBmaWxsPSIjMDAwIi8+PC9n
Pjwvc3ZnPg==');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
