import React from 'react';
import { Switch } from 'react-router';
import { PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN } from '../../account/constants';
import { RoleRoute } from '../../account/components';
import { UsersPageResolver } from './pages';

const UsersRouter = () => (
  <Switch>
    <RoleRoute
      roles={[PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN]}
      exact
      path="/users"
      component={UsersPageResolver}
    />
  </Switch>
);

export default UsersRouter;
