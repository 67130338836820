import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class DocumentTitle extends PureComponent {
  constructor(props) {
    super(props);
    const { children } = props;
    document.title = `${children} - Rubicon Partner`;
  }

  render() {
    return null;
  }
}

export default DocumentTitle;

DocumentTitle.propTypes = {
  children: PropTypes.node,
};

DocumentTitle.defaultProps = {
  children: undefined,
};
