import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import { loading } from '../../styles';

export const MapInfoWindow = styled.div`
  display: flex;
  background-color: ${theme.brandWhite};
  position: absolute;
  bottom: 15px;
  left: -147px;
  width: auto;
  z-index: 1;
  padding: 5px 10px 10px 10px;
  border: 1px solid ${theme.grayDark};
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.18);
`;

export const MapInfoClose = styled.div`
  height: 20px;
  line-height: 20px;
  width: 15px;
  position: relative;
  cursor: pointer;
  text-align: right;
`;

export const MapLoadingOverlay = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  ${props =>
    props.isLoadingLoactions &&
    css`
      display: block;
    `};
`;

export const MapLoadingCircle = styled.div`
  ${loading('40px')};
  align-self: center;
  margin-top: 20px;
`;

export const MapLoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  width: fit-content;
  position: relative;
  top: 45%;
  left: 47%;
`;

export const MarkerLabel = styled.div`
  font-size: 10px;
  margin-top: -24px;
  margin-left: -33px;
  text-align: center;
  background-color: ${theme.brandWhite};
  color: ${theme.brandRed};

  ${props =>
    props.isChecked &&
    css`
      color: ${theme.brandPrimary};
    `};
`;
