import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { fadeAndSlideInUp } from '../../../core/styles';
import { media, mapper } from '../../../utils/styles';
import { RubiconLogoInline } from '../../../common/components/styled';

export const AccountPanelLogo = styled(RubiconLogoInline)`
  width: 220px;
  height: 50px;
  margin: 0 auto 35px;
  animation: ${fadeAndSlideInUp} 0.3s ease-out;
`;

export const AccountPanelTitle = styled.h1`
  margin-bottom: 25px;
  text-align: center;

  ${media.tablet`
    font-size: 22px;
    margin-bottom: 15px;
  `}
`;

export const AccountPanelDescription = styled.div`
  margin-bottom: 25px;
  text-align: center;
  line-height: 20px;

  ${media.tablet`
    margin-bottom: 15px;
  `}
`;

export const AccountPanelError = styled.div`
  margin-bottom: 20px;
  color: ${props => props.theme.brandAlert};
`;

export const AccountPanelLink = styled(Link)`
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 12px;
`;

export const AccountPageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  padding: 20px;

  ${media.tablet`
    height: 100%;
    overflow-y: auto;
  `}
`;

export const AccountPagePanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 50%;

  ${props =>
    props.sizePhone &&
    css`
      ${media.phone`
      flex: ${props => mapper(props.sizePhone, { small: '33.33%', medium: '50%', large: '66.66%' })};
      `};
    `};
`;

export const AccountPage = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #fff;

  ${media.phone`
    flex-direction: column-reverse;
  `};
`;
