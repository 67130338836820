/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';

import GoogleMapReact from 'google-map-react';
import memoizeOne from 'memoize-one';
import supercluster from 'points-cluster';

import PropTypes from 'prop-types';

import MapLegend from './MapLegend';
import ClusterMarker from './markers/ClusterMarker';
import SimpleMarker from './markers/SimpleMarker';
import {
  MapInfoClose,
  MapInfoWindow,
  MapLoadingCircle,
  MapLoadingContent,
  MapLoadingOverlay,
  MarkerLabel,
} from './styled/MapInfoWindow';
import GoogleMapsScript from './scripts/GoogleMapScript';
import GOOGLE_MAP_API_KEY from '../constants/GoogleMapApiKey';
import { MAP_OPTIONS } from '../../common/constants';

const initialPlaceCoords = [
  { lat: 56.846108152602426, lng: -134.7806640625 },
  { lat: 16.462672723808936, lng: -61.91933593749998 },
];

// Return map bounds based on list of places
const getMapBounds = (_, maps, places) => {
  const bounds = new maps.LatLngBounds();
  const locationsCoords = places.length > 0 ? places : initialPlaceCoords;

  locationsCoords.forEach(place => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit bounds to available markers
const fitBounds = (markers, googleMap) => {
  const bounds = getMapBounds(googleMap.map, googleMap.maps, markers);
  googleMap.map.fitBounds(bounds);
  bindResizeListener(googleMap.map, googleMap.maps, bounds);
};

// Show info window or Zoom into cluster
const onChildClickEvent = (hoverKey, { id, points }, clickEnabled, onChildClick, getClusterPoints, clickedMarkerId) => {
  if (clickEnabled && id) {
    onChildClick(hoverKey, id);
  }
  if (id === clickedMarkerId) {
    onChildClick(null, null);
  }
  if (!id) {
    onChildClick(null, null);
    getClusterPoints(points);
  }
};

const getMarker = (markers, markerProps) =>
  markers.find(marker => marker.lat === markerProps.lat && marker.lng === markerProps.lng);

const memoizedMarker = memoizeOne(getMarker);

const infoWindowContent = (
  markers,
  markerProps,
  clickedMarkerId,
  { setOpenedMarkerId, openedMarkerId, onChildClick, InfoWindowContent, googleMap },
) => {
  if (openedMarkerId !== clickedMarkerId) {
    googleMap.map.panTo({ lat: markerProps.lat, lng: markerProps.lng });
    setOpenedMarkerId(clickedMarkerId);
  }

  const marker = memoizedMarker(markers, markerProps);

  return (
    <MapInfoWindow>
      <InfoWindowContent marker={marker} />
      <MapInfoClose onClick={() => onChildClick(null, null)}>X</MapInfoClose>
    </MapInfoWindow>
  );
};

const renderMarkersAndClusters = (
  clusters,
  markers,
  clickedMarkerId,
  infoWindowObject,
  hideZeroCoords,
  simpleMapMarker,
) => {
  const getIsChecked = (markers, markerProps) => {
    const memmodMarker = memoizedMarker(markers, markerProps);
    return memmodMarker && memmodMarker.isChecked;
  };

  const checkIfChecked = points => {
    let isChecked = false;
    for (let i = 0; i < points.length; i++) {
      if (points[i].isChecked) {
        isChecked = true;
        break;
      }
    }
    return isChecked;
  };

  const memoizedClusterCheck = memoizeOne(checkIfChecked);

  return clusters.map(({ numPoints, clusterId, ...markerProps }) => {
    if (hideZeroCoords && !(markerProps.lng && markerProps.lat)) {
      return null;
    }

    if (numPoints === 1) {
      const { zip: markerLabel, isChecked } = getMarker(markers, markerProps);
      // eslint-disable-next-line prefer-destructuring
      return (
        <SimpleMarker
          id={clusterId}
          key={clusterId}
          {...markerProps}
          isChecked={getIsChecked(markers, markerProps)}
          simpleMapMarker={simpleMapMarker}
        >
          <MarkerLabel isChecked={isChecked}>{markerLabel}</MarkerLabel>
          {clickedMarkerId === `${markerProps.lng}:${markerProps.lat}` &&
            infoWindowContent(markers, markerProps, clickedMarkerId, infoWindowObject)}
        </SimpleMarker>
      );
    }
    return <ClusterMarker key={clusterId} isChecked={memoizedClusterCheck(markerProps.points)} {...markerProps} />;
  });
};

const memoizedMarkersAndClusters = memoizeOne(renderMarkersAndClusters);

export const useClusters = ({ clusterRadius = 60, initialZoom = 5, options, getBounds = () => {}, markersList }) => {
  const [clickedMarkerId, setClickedMarkerId] = useState('');
  const [clusters, setClusters] = useState([]);
  const [mapProps, setMapProps] = useState({
    center: { lat: 39.5, lng: -98.35 },
    zoom: initialZoom,
  });

  const onChange = ({ center, zoom, bounds }) => {
    setMapProps({ center, zoom, bounds });
  };

  const onChildClick = id => {
    setClickedMarkerId(id);
  };

  const getCluster = supercluster(markersList, {
    minZoom: options.minZoom, // min zoom to generate clusters on
    maxZoom: options.maxZoom - 1, // max zoom level to cluster the points on
    radius: clusterRadius, // cluster radius in pixels
  });

  useEffect(() => {
    if (mapProps.bounds) {
      setClusters(
        getCluster(mapProps).map(({ wx, wy, points, numPoints }) => ({
          lat: wy,
          lng: wx,
          text: numPoints,
          numPoints,
          points,
          clusterId: `${wx}:${wy}`,
        })),
      );
    } else {
      setClusters([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapProps]);

  // get map bounds to filter location list
  useEffect(() => {
    getBounds(mapProps.bounds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapProps]);

  return [mapProps, onChange, onChildClick, clickedMarkerId, clusters];
};

export const ClusterMapComponent = ({
  style,
  hoverDistance = 30,
  clickEnabled = false,
  height = 'auto',
  options = { minZoom: 3, maxZoom: 15 },
  InfoWindowContent,
  legendItems,
  isLoadingLoactions = false,
  onSearchBounds = 0,
  hideZeroCoords = false,
  initialZoomSetting = undefined,
  markersList,
  clusterRadius,
  initialZoom,
  getBounds,
  simpleMapMarker,
  styled,
}) => {
  const [didMount, setDidMount] = useState(true);
  const [googleMap, setMap] = useState(undefined);
  const [selectedCluster, getClusterPoints] = useState(null);
  const [openedMarkerId, setOpenedMarkerId] = useState(null);
  const [prevSearchBounds, setPrevSearchBounds] = useState(0);

  const [mapProps, onChange, onChildClick, clickedMarkerId, clusters] = useClusters({
    clusterRadius,
    initialZoom,
    options,
    getBounds,
    markersList,
  });

  const { center, zoom } = mapProps;

  const infoWindowObject = {
    openedMarkerId,
    setOpenedMarkerId,
    googleMap,
    onChildClick,
    InfoWindowContent,
  };

  useEffect(() => {
    const fitBoundsCondition = googleMap && !isLoadingLoactions;

    if (didMount && fitBoundsCondition) {
      fitBounds(markersList, googleMap);
      setDidMount(false);
    }

    if (prevSearchBounds !== onSearchBounds && fitBoundsCondition) {
      fitBounds(markersList, googleMap);
    }

    setPrevSearchBounds(onSearchBounds);
  }, [onSearchBounds, prevSearchBounds, isLoadingLoactions, googleMap, markersList, didMount]);

  useEffect(() => {
    if (selectedCluster !== null) {
      fitBounds(selectedCluster, googleMap);
    }
  }, [googleMap, selectedCluster]);

  const compStyles = style || {
    position: 'relative',
    margin: 0,
    padding: 0,
    flex: 1,
  };

  return (
    <div style={{ position: 'relative' }}>
      <GoogleMapReact
        style={{ height, ...compStyles }}
        hoverDistance={hoverDistance}
        center={center}
        zoom={initialZoomSetting || zoom}
        resetBoundsOnResize
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
        onChange={onChange}
        onChildClick={(hoverKey, props) =>
          onChildClickEvent(hoverKey, props, clickEnabled, onChildClick, getClusterPoints, clickedMarkerId)
        }
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap({ map, maps });
        }}
        options={opt => {
          if (!styled) return { minZoom: 2, maxZoom: 15 };
          return {
            minZoom: 1,
            maxZoom: 15,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: opt.MapTypeControlStyle.HORIZONTAL_BAR,
              position: opt.ControlPosition.TOP_LEFT,
              mapTypeIds: [opt.MapTypeId.ROADMAP, opt.MapTypeId.SATELLITE, opt.MapTypeId.HYBRID],
            },
            styles: MAP_OPTIONS.styles,
          };
        }}
      >
        <MapLoadingOverlay isLoadingLoactions={isLoadingLoactions}>
          <MapLoadingContent>
            Loading map locations...
            <MapLoadingCircle />
          </MapLoadingContent>
        </MapLoadingOverlay>
        {memoizedMarkersAndClusters(
          clusters,
          markersList,
          clickedMarkerId,
          infoWindowObject,
          hideZeroCoords,
          simpleMapMarker,
        )}
      </GoogleMapReact>
      {legendItems && legendItems.length > 0 && <MapLegend items={legendItems} />}
    </div>
  );
};

ClusterMapComponent.propTypes = {
  markersList: PropTypes.array.isRequired,
  style: PropTypes.object,
  hoverDistance: PropTypes.number,
  clickEnabled: PropTypes.bool,
  height: PropTypes.string,
  options: PropTypes.object,
  InfoWindowContent: PropTypes.func,
  legendItems: PropTypes.array,
  isLoadingLoactions: PropTypes.bool,
  onSearchBounds: PropTypes.number,
  hideZeroCoords: PropTypes.bool,
  initialZoomSetting: PropTypes.number,
  clusterRadius: PropTypes.number,
  initialZoom: PropTypes.number,
  getBounds: PropTypes.func,
  simpleMapMarker: PropTypes.bool,
  styled: PropTypes.bool,
};

ClusterMapComponent.defaultProps = {
  style: {},
  hoverDistance: 30,
  clickEnabled: false,
  height: '400px',
  options: { minZoom: 3, maxZoom: 15 },
  InfoWindowContent: () => {},
  legendItems: [],
  isLoadingLoactions: false,
  onSearchBounds: 0,
  hideZeroCoords: false,
  initialZoomSetting: undefined,
  clusterRadius: 60,
  initialZoom: 5,
  // eslint-disable-next-line no-unused-vars
  getBounds: _arg => {},
  simpleMapMarker: false,
  styled: false,
};

const ClusterMapScript = props => {
  // eslint-disable-next-line react/prop-types
  const { hasMapLoaded } = props;
  if (hasMapLoaded) {
    return <ClusterMapComponent {...props} />;
  }

  return (
    <GoogleMapsScript>
      <ClusterMapComponent {...props} />
    </GoogleMapsScript>
  );
};

export default ClusterMapScript;
