import { mapKeys } from 'lodash-es';

export const NEW_SERVICE = {
  id: 0,
  name: 'New service',
};

export const ALLIED_WASTE_SERVICES = {
  id: 1,
  name: 'Allied Waste Services',
};

export const AMERICAN_DISPOSAL_SERVICES = {
  id: 2,
  name: 'American Disposal Services',
};

export const DEFFENBAUGH_INDUSTRIES = {
  id: 3,
  name: 'Deffenbaugh Industries',
};

export const PROGRESSIVE_WASTE_SOLUTIONS = {
  id: 4,
  name: 'Progressive Waste Solutions',
};

export const REPUBLIC_SERVICES = {
  id: 5,
  name: 'Republic Services',
};

export const WASTE_CONNECTIONS = {
  id: 6,
  name: 'Waste Connections',
};

export const WEASTE_MANAGEMENT = {
  id: 7,
  name: 'Waste Management',
};

export const GROOT = {
  id: 8,
  name: 'Groot',
};

export const SERVICE_PROVIDERS = mapKeys(
  [
    NEW_SERVICE,
    ALLIED_WASTE_SERVICES,
    AMERICAN_DISPOSAL_SERVICES,
    DEFFENBAUGH_INDUSTRIES,
    PROGRESSIVE_WASTE_SOLUTIONS,
    REPUBLIC_SERVICES,
    WASTE_CONNECTIONS,
    WEASTE_MANAGEMENT,
    GROOT,
  ],
  'id',
);

export const SERVICE_PROVIDERS_OPTIONS = mapKeys(
  [
    NEW_SERVICE,
    ALLIED_WASTE_SERVICES,
    AMERICAN_DISPOSAL_SERVICES,
    DEFFENBAUGH_INDUSTRIES,
    PROGRESSIVE_WASTE_SOLUTIONS,
    REPUBLIC_SERVICES,
    WEASTE_MANAGEMENT,
    GROOT,
  ],
  'id',
);
