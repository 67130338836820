import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import { PanelSearch as PanelSearchContainer, PanelSearchIcon, PanelSearchInput } from './styled';

const PanelSearch = ({ input, ...props }) => (
  <PanelSearchContainer>
    <PanelSearchIcon />
    <PanelSearchInput {...input} placeholder="Search" {...props} />
  </PanelSearchContainer>
);

PanelSearch.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
};

export default PanelSearch;
