import React from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { FormGroup, FormLabel, FormError, Select as FormSelect } from './styled';
import isFalsy from '../../utils/services/isFalsy';

const Select = ({
  input,
  meta: { asyncValidating, submitFailed, error },
  label,
  isLoading,
  children,
  options,
  margin,
  ...props
}) => (
  <FormGroup hasValue={!isFalsy(input.value)} isLoading={isLoading || asyncValidating} margin={margin}>
    {!!label && <FormLabel>{label}</FormLabel>}

    <FormSelect {...input} isEmpty={!input.value} {...props}>
      {children}

      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </FormSelect>

    {submitFailed && error && <FormError>{error}</FormError>}
  </FormGroup>
);

Select.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  options: PropTypes.array,
  isLoading: PropTypes.bool,
  margin: PropTypes.string,
  label: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  children: undefined,
  isLoading: false,
  margin: undefined,
  label: undefined,
};

export default Select;
