import { checkAvailablePhoneNumber } from '../../../../account/services/account';

const phoneRegex = new RegExp(/^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/);

export const isAvailablePhone = (values) => new Promise((resolve, reject) => {
  if (values.customerPhone && phoneRegex.test(values.customerPhone)) {
  checkAvailablePhoneNumber({ phoneNumber: values.customerPhone })
  .then((result) => {
    resolve();
  })
  .catch(() => {
    reject({ customerPhone: 'This phone number cannot be used!' })
  });
  }
});
