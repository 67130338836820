import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DesktopNavBarButton,
  NavigationBarIcon,
  DesktopNavBarMenu,
  DesktopNavBarMenuButton,
  DesktopNavBarMenuItem,
} from '../styled';
import { navItemsMetadata } from '../../navigation';

class DesktopNavBarSettings extends Component {
  state = {
    isSettingsNavigationMenuOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSettingsNavigationMenu);
  }

  openSettingsNavigationMenu = () => {
    this.setState({ isSettingsNavigationMenuOpen: true });
    document.addEventListener('click', this.closeSettingsNavigationMenu);
  };

  closeSettingsNavigationMenu = () => {
    this.setState({ isSettingsNavigationMenuOpen: false });
    document.removeEventListener('click', this.closeSettingsNavigationMenu);
  };

  render() {
    const { username, isPartnerSuperAdmin, openUserProfileModal } = this.props;
    const { isSettingsNavigationMenuOpen } = this.state;
    const { logout } = navItemsMetadata;

    return (
      <>
        <DesktopNavBarButton onClick={this.openSettingsNavigationMenu} isHighlighted={isSettingsNavigationMenuOpen}>
          <NavigationBarIcon icon="settingsCircle" />
          {username}
          <DesktopNavBarMenu isOpen={isSettingsNavigationMenuOpen}>
            {!isPartnerSuperAdmin && (
              <DesktopNavBarMenuButton onClick={openUserProfileModal}>Account Profile</DesktopNavBarMenuButton>
            )}
            <DesktopNavBarMenuItem to={logout.to}>{logout.caption}</DesktopNavBarMenuItem>
          </DesktopNavBarMenu>
        </DesktopNavBarButton>
      </>
    );
  }
}

DesktopNavBarSettings.propTypes = {
  username: PropTypes.string.isRequired,
  isPartnerSuperAdmin: PropTypes.bool.isRequired,
  openUserProfileModal: PropTypes.func.isRequired,
};

export default DesktopNavBarSettings;
