import { mapKeys } from 'lodash-es';
import { PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER } from '../../account/constants';

export const USER_TYPES = mapKeys(
  [
    { name: 'Partner Admin', value: PARTNER_ADMIN },
    { name: 'Partner Manager', value: PARTNER_MANAGER },
    { name: 'Partner User', value: PARTNER_USER },
  ],
  'value',
);
