import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalDialog, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { resetUser } from '../../ducks';
import { UserEditorForm } from '../forms';

class UserEditorModal extends PureComponent {
  componentWillUnmount() {
    const { resetUser } = this.props;
    resetUser();
  }

  render() {
    const { isLoading, isSaving, userId, saveUser, closeModal } = this.props;

    return (
      <Modal isLoading={isLoading || isSaving}>
        <ModalDialog size="medium" sizeTablet="medium" sizePhone="medium">
          <ModalClose onClick={closeModal}>
            <ModalCloseIcon />
          </ModalClose>

          <ModalTitle>{userId ? 'Edit user' : 'Create user'}</ModalTitle>
          <UserEditorForm userId={userId} onSubmit={saveUser} />
        </ModalDialog>
      </Modal>
    );
  }
}

UserEditorModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  resetUser: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

UserEditorModal.defaultProps = {
  userId: undefined,
};

const mapStateToProps = state => ({
  isLoading: state.users.user.isLoading,
  isSaving: state.users.user.isSaving,
});

const mapDispatchToProps = { resetUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEditorModal);
