/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fieldInputPropTypes, fieldMetaPropTypes, Field } from 'redux-form';
import { indexOf, find, get, isNumber, debounce } from 'lodash-es';
import { TypeAhead } from '../../core/components';
import { FormGroup, FormLabel, FormError } from '../../core/components/styled';
import { loadAddresses, resetAddresses } from '../ducks';

class LocationPicker extends Component {
  searchAddress = debounce((searchTerm, onOptionsLoaded) => {
    if (searchTerm.length < 1) {
      onOptionsLoaded([]);
      return;
    }

    this.service.getPlacePredictions(
      {
        input: searchTerm,
        types: ['address'],
        // componentRestrictions: { country: 'us' },
      },
      (predictions, status) => this.displaySuggestions(predictions, status, searchTerm, onOptionsLoaded),
    );
  }, 100);

  constructor(props) {
    super(props);
    this.state = { value: this.getFormattedAddress() };
  }

  componentDidMount() {
    this.service = new google.maps.places.AutocompleteService();
  }

  componentWillUnmount() {
    const { resetAddresses } = this.props;
    resetAddresses();
  }

  onChange = (event, value) => {
    const {
      input: { onChange },
      apiAddresses,
    } = this.props;

    if (isNumber(value)) {
      onChange({ id: value || null, ...apiAddresses.find(address => address.id === value) });
    } else {
      const request = {
        placeId: value,
        fields: ['ALL'],
      };

      const map = new google.maps.Map(document.createElement('div'));
      this.googlePlaces = new google.maps.places.PlacesService(map);
      this.googlePlaces.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const location = this.getLocation(place);
          onChange({ id: value || null, ...location });
        }
      });
    }
  };

  getFormattedAddress = () => {
    const { input } = this.props;
    return input.value.line1 || '';
  };

  getLocation = place => ({
    latitude: place.geometry ? place.geometry.location.lat() : undefined,
    longitude: place.geometry ? place.geometry.location.lng() : undefined,
    streetNumber: this.getStreetNumber(place),
    street: this.getStreet(place),
    city: this.getCity(place),
    state: this.getState(place),
    country: this.getCountry(place),
    zip: this.getZip(place),
    line1: place.formatted_address,
  });

  getStreetNumber = place => this.getAddressComponent(place, 'street_number');

  getStreet = place => this.getAddressComponent(place, 'route');

  getCity = place =>
    this.getAddressComponent(place, 'locality') ||
    this.getAddressComponent(place, 'sublocality') ||
    this.getAddressComponent(place, 'neighborhood') ||
    this.getAddressComponent(place, 'administrative_area_level_3');

  getState = place => this.getAddressComponent(place, 'administrative_area_level_1');

  getCountry = place => this.getAddressComponent(place, 'country');

  getZip = place => this.getAddressComponent(place, 'postal_code');

  getAddressComponent = (place, type) => {
    const addressComponent = find(
      place.address_components,
      addressComponent => indexOf(addressComponent.types, type) > -1,
    );

    return get(addressComponent, 'short_name', '');
  };

  addressFormatter = (addressFirstPart, stringOffset, stringLength, addressSecondPart, wholeAddress) => {
    const highlightedText = addressFirstPart.substr(stringOffset, stringLength);
    const firstPart = stringOffset > 0 ? addressFirstPart.substr(0, stringOffset) : '';
    const secondPardt = addressFirstPart.substr(stringOffset + stringLength, addressFirstPart.length);

    return (
      <span address={wholeAddress}>
        {firstPart}
        <span style={{ color: 'black', fontWeight: 'bold' }}>{highlightedText}</span>
        {secondPardt} <span style={{ color: 'grey', fontSize: '12px' }}>{addressSecondPart}</span>
      </span>
    );
  };

  formatAddressForDropdown = predictions => {
    let addressFirstPart;
    let addressSecondPart;
    let stringLength;
    let stringOffset;
    let wholeAddress;

    const options = predictions.map(prediction => {
      // google address structure
      if (prediction.structured_formatting) {
        const addressAttributes = prediction.structured_formatting;
        wholeAddress = prediction.description;
        addressFirstPart = addressAttributes.main_text;
        addressSecondPart = addressAttributes.secondary_text;
        stringLength = addressAttributes.main_text_matched_substrings[0].length;
        stringOffset = addressAttributes.main_text_matched_substrings[0].offset;
      }

      // saving api address structure
      if (prediction.display) {
        wholeAddress = prediction.line1;
        addressFirstPart = prediction.display.firstPart;
        addressSecondPart = prediction.display.secondPart;
        stringLength = prediction.matches[0].length;
        stringOffset = prediction.matches[0].position;
      }

      const formatted = this.addressFormatter(
        addressFirstPart,
        stringOffset,
        stringLength,
        addressSecondPart,
        wholeAddress,
      );

      return {
        label: formatted,
        value: prediction.place_id || prediction.id,
      };
    });

    return options;
  };

  displaySuggestions = (predictions, status, searchTerm, onOptionsLoaded) => {
    let addressOptions;

    if (predictions) {
      addressOptions = this.formatAddressForDropdown(predictions);
      onOptionsLoaded(addressOptions);
    } else {
      const { loadAddresses } = this.props;
      loadAddresses(searchTerm)
        .then(() => {
          const { apiAddresses } = this.props;
          addressOptions = this.formatAddressForDropdown(apiAddresses);
          onOptionsLoaded(addressOptions);
        })
        .catch(() => {});
    }
  };

  render() {
    const {
      meta: { active, dirty, asyncValidating, submitFailed, error },
      placeholder,
      label,
      instantValidation,
      isLoading,
      margin,
    } = this.props;

    const { value } = this.state;

    return (
      <FormGroup hasValue={value} isActive={active} isLoading={isLoading || asyncValidating} margin={margin}>
        <FormLabel>{value !== '' && label}</FormLabel>

        <Field
          name="location"
          noLengthRestriction
          component={TypeAhead}
          placeholder={placeholder || 'Address'}
          inputValue={value}
          getOptions={this.searchAddress}
          onChange={this.onChange}
          margin="no"
        />

        {(submitFailed || (dirty && instantValidation)) && error && <FormError>{error}</FormError>}
      </FormGroup>
    );
  }
}

LocationPicker.propTypes = {
  apiAddresses: PropTypes.array.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  loadAddresses: PropTypes.func.isRequired,
  resetAddresses: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  instantValidation: PropTypes.bool,
  margin: PropTypes.string,
};

LocationPicker.defaultProps = {
  isLoading: false,
  placeholder: undefined,
  label: undefined,
  instantValidation: false,
  margin: undefined,
};

const mapStateToProps = state => ({
  apiAddresses: state.common.addresses.addresses,
});

const mapDispatchToProps = {
  loadAddresses,
  resetAddresses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationPicker);
