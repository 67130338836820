import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SERVICE_TYPES } from '../constants';
import {
  ServiceTypeOption as ServiceTypeOptionContainer,
  ServiceTypeOptionImage,
  ServiceTypeOptionLabel,
} from './styled';

class ServiceTypeOption extends PureComponent {
  render() {
    const { isSelected, type, onClick } = this.props;
    return (
      <ServiceTypeOptionContainer isSelected={isSelected} type={type} onClick={() => onClick(type)}>
        <ServiceTypeOptionImage />
        <ServiceTypeOptionLabel>{SERVICE_TYPES[type].name}</ServiceTypeOptionLabel>
      </ServiceTypeOptionContainer>
    );
  }
}

ServiceTypeOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ServiceTypeOption;
