import styled from 'styled-components';
import { sizeMapper, colorMapper } from '../../../utils/styles';
import { Icon } from '..';

export const TimelineProgressBar = styled.div`
  width: ${props => props.width};
  height: 100%;
  background-color: ${props => props.theme.brandPrimary};
  border-radius: 3px;
`;

export const TimelineProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: ${props => props.theme.grayLighter};
  border-radius: 3px;
`;

export const TimelineEventLabel = styled.span`
  display: block;
  font-weight: ${props => props.theme.fontWeightMedium};
`;

export const TimelineEventDate = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeightLight};
`;

export const TimelineEventIcon = styled(Icon).attrs({ icon: 'circle' })`
  position: relative;
  top: -4px;
  width: 12px;
  height: 12px;
  margin-bottom: 6px;
`;

export const TimelineEvent = styled.div`
  width: 105px;
  position: relative;
  text-align: center;
  font-size: 10px;
  color: ${colorMapper};
`;

export const Timeline = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;
