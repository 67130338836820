import moment from 'moment';
import Cookie from 'js-cookie';
import { AUTH_TOKEN_EXPIRATION_TIME, REFRESH_TOKEN_EXPIRATION_TIME } from '../constants';

export const setSessionValues = ({ user, authToken, refreshToken }) => {
  const authTokenExpires = moment()
    .add(AUTH_TOKEN_EXPIRATION_TIME, 'minutes')
    .toDate();

  const refreshTokenExpires = moment()
    .add(REFRESH_TOKEN_EXPIRATION_TIME, 'minutes')
    .toDate();

  if (user) Cookie.set('user', user, { expires: authTokenExpires });
  if (authToken) Cookie.set('authToken', authToken, { expires: authTokenExpires });
  if (refreshToken) Cookie.set('refreshToken', refreshToken, { expires: refreshTokenExpires });
};

export const createSession = (user, authToken, refreshToken) =>
  setSessionValues({
    user,
    authToken,
    refreshToken,
  });

export const getSessionValue = name => Cookie.getJSON(name);

export const destroySession = () => {
  Cookie.remove('user');
  Cookie.remove('authToken');
  Cookie.remove('refreshToken');
};
