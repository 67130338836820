import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonSet, Button, Container } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelTitle, AccountPanelDescription, AccountPanelLink } from '../styled';
import { isRequired, isEmail } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const RequestPasswordResetForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelTitle>Forgot your password?</AccountPanelTitle>
    <AccountPanelDescription>
      Enter your email address and we’ll send you instructions to reset your password
    </AccountPanelDescription>

    <Field name="email" component={Input} label="Email address" type="text" validate={[isRequired, isEmail]} />

    <ButtonSet margin="medium no">
      <Button type="submit" color="primary">
        Reset password
      </Button>
    </ButtonSet>

    <Container align="center">
      <AccountPanelLink to="/account/login">Back to Login</AccountPanelLink>
    </Container>
  </form>
);

RequestPasswordResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'requestPasswordReset',
  onSubmitFail: focusFirstInvalidField,
})(RequestPasswordResetForm);
