import update from 'immutability-helper';
import doLoadCompanies from '../services/loadCompanies';

// Actions
const START_LOAD = 'users/companies/START_LOAD';
const COMPLETE_LOAD = 'users/companies/COMPLETE_LOAD';
const FAIL_LOAD = 'users/companies/FAIL_LOAD';
const RESET = 'users/companies/RESET';

// Initial state
const initialState = {
  isLoading: false,
  companies: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          companies: action.companies,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = companies => ({
  type: COMPLETE_LOAD,
  companies,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadCompanies = () => dispatch => {
  dispatch(startLoad());
  const loadCompaniesPromise = doLoadCompanies();
  loadCompaniesPromise.then(companies => dispatch(completeLoad(companies))).catch(() => dispatch(failLoad()));

  return loadCompaniesPromise;
};

export const resetCompanies = () => ({
  type: RESET,
});
