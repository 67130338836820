import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelError, AccountPanelTitle } from '../styled';
import { isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const matchPassword = (value, allValues) => (value === allValues.password ? undefined : 'Passwords do not match.');

const ResetPasswordForm = ({ handleSubmit, errorMessage, isResetPasswordFailed }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelTitle>Reset your password</AccountPanelTitle>

    <Field name="password" component={Input} label="Create new password" type="password" validate={[isRequired]} />

    <Field
      name="passwordConfirmation"
      component={Input}
      label="Retype new password"
      type="password"
      validate={[isRequired, matchPassword]}
    />
    {isResetPasswordFailed && <AccountPanelError>{errorMessage}</AccountPanelError>}
    <ButtonSet>
      <Button type="submit" color="primary">
        Reset password
      </Button>
    </ButtonSet>
  </form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isResetPasswordFailed: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'resetPassword',
  onSubmitFail: focusFirstInvalidField,
})(ResetPasswordForm);
