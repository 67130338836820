import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MapLegendContainer,
  MapLegendHeader,
  MapLegendToggle,
  MapLegendTitle,
  MapLegendBody,
  MapLegendItem,
  MapLegendItemIcon,
} from './styled/Map';
import GreenPin from '../assets/img/pin_green.svg';
import GrayPin from '../assets/img/pin_grey.svg';
import RedPin from '../assets/img/pin_red.svg';

function getIcon(icon) {
  switch (icon) {
    case 'green':
      return GreenPin;
    case 'gray':
      return GrayPin;
    case 'red':
      return RedPin;
    default:
      return icon;
  }
}

const MapLegend = ({ items }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <MapLegendContainer expanded={expanded}>
      <MapLegendHeader>
        <MapLegendTitle>Legend</MapLegendTitle>
        <MapLegendToggle expanded={expanded} onClick={toggleExpand} />
      </MapLegendHeader>
      {expanded && (
        <MapLegendBody>
          {items &&
            items.map(item => (
              <MapLegendItem key={item.label}>
                <MapLegendItemIcon icon={getIcon(item.icon)} />
                {item.label}
              </MapLegendItem>
            ))}
        </MapLegendBody>
      )}
    </MapLegendContainer>
  );
};

MapLegend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      label: PropTypes.string.isRequired,
    }),
  ),
};

MapLegend.defaultProps = {
  items: [],
};

export default MapLegend;
