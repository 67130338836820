import React from 'react';
import PropTypes from 'prop-types';
import { Motion } from 'react-motion';
import useClusterMarkerMemoized from './clusterHooks';
import { SimpleMarkerStyled } from './SimpleMarkerStyled';

export const simpleMarker = ({
  initialScale,
  defaultScale,
  hoveredScale,
  stiffness,
  damping,
  precision,
  isChecked,
  children,
}) => {
  const [motionStyle = {}] = useClusterMarkerMemoized({
    initialScale,
    defaultScale,
    hoveredScale,
    stiffness,
    damping,
    precision,
  });

  return (
    <Motion style={motionStyle}>
      {() => (
        <div>
          <SimpleMarkerStyled isChecked={isChecked} />
          {children}
        </div>
      )}
    </Motion>
  );
};

simpleMarker.propTypes = {
  children: PropTypes.node.isRequired,
  initialScale: PropTypes.number,
  defaultScale: PropTypes.number,
  hoveredScale: PropTypes.number,
  stiffness: PropTypes.number,
  damping: PropTypes.number,
  precision: PropTypes.number,
  isChecked: PropTypes.bool,
};

simpleMarker.defaultProps = {
  initialScale: 0.6,
  defaultScale: 1,
  hoveredScale: 1.15,
  stiffness: 320,
  damping: 7,
  precision: 0.001,
  isChecked: true,
};

export default simpleMarker;
