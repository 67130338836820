import { mapKeys, mapValues } from 'lodash-es';
import http from '../../core/services/http';
import transformLoadFranchiseForAddress from './transformLoadFranchise';

const loadFranchiseForAddresses = addresses =>
  http
    .post('customer/price/market/check', transformLoadFranchiseForAddress(addresses))
    .then(response => mapValues(mapKeys(response.data.addresses, 'standardizedAddress'), 'franchised'));

export default loadFranchiseForAddresses;
