import { mapKeys, map } from 'lodash-es';

export const transformLoadQuote = ({ uid, locations, ...quote }) => ({
  ...quote,
  id: uid,
  locations: mapKeys(
    map(locations, ({ uid, services, ...location }) => ({
      ...location,
      id: uid,
      address: location.address.latitude && location.address.longitude ? location.address : undefined,
      services: mapKeys(
        map(services, ({ uid, equipmentType, recurringFrequency, ...service }) => ({
          ...service,
          id: uid,
          recurringFrequency: Number(recurringFrequency),
          containerSize: Number(equipmentType),
        })),
        'id',
      ),
    })),
    'id',
  ),
});

export const transformSaveQuote = ({ id, locations, ...quote }) => ({
  ...quote,
  uid: id,
  locations: map(locations, ({ id, services, ...location }) => ({
    ...location,
    uid: id,
    services: map(services, ({ id, containerSize, ...service }) => ({
      ...service,
      uid: id,
      equipmentType: containerSize,
    })),
  })),
});
