const transformLogin = ({
  firstName,
  lastName,
  roles,
  userId,
  company,
  phoneConfirmed,
  hasIncumbentRebateProgram,
  hasRecognitionProgram,
  isPasswordExpired,
}) => ({
  firstName,
  lastName,
  roles,
  userId,
  company,
  phoneConfirmed,
  hasIncumbentRebateProgram,
  hasRecognitionProgram,
  isPasswordExpired,
});

export default transformLogin;
