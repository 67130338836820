import React from 'react';
import { Switch } from 'react-router';
import { PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN } from '../../account/constants';
import { RoleRoute } from '../../account/components';
import MarketAvailabilityPage from './pages/MarketAvailabilityPage';

const MarketRouter = () => (
  <Switch>
    <RoleRoute
      roles={[PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN]}
      exact
      path="/market/market-availability"
      component={MarketAvailabilityPage}
    />
  </Switch>
);

export default MarketRouter;
