import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { ButtonSet, Button } from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { AccountPanelError, AccountPanelTitle } from '../styled';
import { isRequired } from '../../../utils/services/validator';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';

const matchPassword = (value, allValues) => (value === allValues.newPassword ? undefined : 'Passwords do not match.');

const ExpiredPasswordForm = ({ handleSubmit, errorMessage, isChangeingPasswordFailed }) => (
  <form onSubmit={handleSubmit} noValidate>
    <AccountPanelTitle>Update your password</AccountPanelTitle>

    <Field name="oldPassword" component={Input} label="Old password" type="password" validate={[isRequired]} />

    <Field name="newPassword" component={Input} label="Create new password" type="password" validate={[isRequired]} />

    <Field
      name="newPasswordConfirmation"
      component={Input}
      label="Retype new password"
      type="password"
      validate={[isRequired, matchPassword]}
    />
    {isChangeingPasswordFailed && <AccountPanelError>{errorMessage}</AccountPanelError>}
    <ButtonSet>
      <Button type="submit" color="primary">
        Update password
      </Button>
    </ButtonSet>
  </form>
);

ExpiredPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isChangeingPasswordFailed: PropTypes.any.isRequired,
  errorMessage: PropTypes.any.isRequired,
};

export default reduxForm({
  form: 'expiredPassword',
  onSubmitFail: focusFirstInvalidField,
})(ExpiredPasswordForm);
