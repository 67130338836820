import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';
import { Icon } from '..';
import { sizeMapper, colorMapper, media } from '../../../utils/styles';

export const BareButtonStyle = `
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  color: inherit;
  user-select: none;
`;

export const ButtonIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin: ${props => sizeMapper(props.margin, 'no no small no')};
  vertical-align: top;
`;

export const Button = styled.button`
  ${BareButtonStyle};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: 9px 50px;
  background-color: ${colorMapper};
  border-radius: 5px;
  text-transform: capitalize;
  line-height: 18px;
  font-size: 14px;
  color: #fff;

  &:active {
    background-color: ${props => darken(0.05, colorMapper(props))};
  }

  &:disabled {
    opacity: 0.5;
  }

  ${props =>
    props.smallSize &&
    css`
      padding: 4px 10px;
      font-size: 13px;
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  ${props =>
    props.fluid &&
    css`
      flex: 1;
    `};

  ${props =>
    props.line &&
    css`
      background-color: transparent;
      border: 1px solid ${colorMapper};
      color: ${colorMapper};

      &:active {
        background-color: transparent;
        border-color: ${props => darken(0.05, colorMapper(props))};
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `};

  ${props =>
    props.text &&
    css`
      padding: 9px 0;
      background-color: transparent;
      color: ${colorMapper};

      ${props =>
        props.noPadding &&
        css`
          padding: 0;
        `};

      &:active {
        background-color: transparent;
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `};

  ${props =>
    props.marginTablet &&
    css`
      ${media.tablet`
        margin: ${props => sizeMapper(props.marginTablet)};
      `};
    `}

  ${props =>
    props.marginPhone &&
    css`
      ${media.phone`
        margin: ${props => sizeMapper(props.marginPhone)};
      `}
    `}

  ${media.tablet`
      padding: 9px 25px;
      font-size: 13px;
  `}

  ${media.phone`
      padding: 9px 15px;
      min-width: 100px; 
      font-size: 12px;
  `}

  ${props =>
    props.noPaddingMedia &&
    css`
      ${media.phone`
        padding: 0;
      `}
      ${media.tablet`
        padding: 0;
      `}
    `}
`;

export const ButtonLink = Button.withComponent(
  ({ margin, marginTablet, marginPhone, noPadding, color, fluid, line, text, ...props }) => <Link {...props} />,
);
