import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, fieldInputPropTypes } from 'redux-form';
import { createSelector } from 'reselect';
import { map, identity } from 'lodash-es';
import { isRequired } from '../../utils/services/validator';
import { Select } from '../../core/components';
import { WASTE_TYPES, WASTE_TYPES_BY_SERVICE_TYPE } from '../constants';

const wasteTypeOptionsSelector = createSelector(
  (serviceType, acceptedWasteTypes) => {
    const wasteTypes = acceptedWasteTypes || WASTE_TYPES_BY_SERVICE_TYPE[serviceType];

    return map(wasteTypes, wasteType => ({
      label: WASTE_TYPES[wasteType].name,
      value: wasteType,
    }));
  },
  identity,
);

class WasteTypeSelect extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props) {
    const {
      acceptedWasteTypes,
      input: { value, onChange },
    } = props;

    if (acceptedWasteTypes && acceptedWasteTypes.length && acceptedWasteTypes.indexOf(value) === -1 && value) {
      onChange('');
    }

    return null;
  }

  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      input: { name },
      serviceType,
      acceptedWasteTypes,
    } = this.props;

    const wasteTypeOptions = wasteTypeOptionsSelector(serviceType, acceptedWasteTypes);

    return (
      <Field
        name={name}
        component={Select}
        label="Waste type"
        options={wasteTypeOptions}
        validate={[isRequired]}
        onChange={this.onChange}
      >
        <option value="" />
      </Field>
    );
  }
}

WasteTypeSelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  serviceType: PropTypes.string,
  acceptedWasteTypes: PropTypes.arrayOf(PropTypes.string),
};

WasteTypeSelect.defaultProps = {
  serviceType: undefined,
  acceptedWasteTypes: undefined,
};

export default WasteTypeSelect;
