import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addLastLocation } from '../ducks';

class LastLocationsProvider extends PureComponent {
  componentDidMount() {
    const {
      history,
      location: { pathname, search },
      addLastLocation,
    } = this.props;
    addLastLocation(pathname, `${pathname}${search}`);
    history.listen(({ pathname, search }) => addLastLocation(pathname, `${pathname}${search}`));
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

LastLocationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: RouterPropTypes.history.isRequired,
  location: RouterPropTypes.location.isRequired,
  addLastLocation: PropTypes.func.isRequired,
};

const mapDispatchToProps = { addLastLocation };

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(LastLocationsProvider),
);
