import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { loadServiceOptions, resetServiceOptions } from '../../../common/ducks';
import { locationByServiceIdSelector, serviceByIdSelector } from '../../ducks';
import ServiceEditorPage from './ServiceEditorPage';

class ServiceEditorPageResolver extends PureComponent {
  componentWillUnmount() {
    const { resetServiceOptions } = this.props;
    resetServiceOptions();
  }

  resolve = async () => {
    const { location, serviceId, service, loadServiceOptions } = this.props;
    const address = {
      zipCode: location.address.zip,
      address: location.address.line1,
    };

    return location && (!serviceId || !!service)
      ? loadServiceOptions(address)
      : Promise.reject(new Error('Invalid location or service id.'));
  };

  render() {
    return (
      <>
        <DocumentTitle>Service details</DocumentTitle>
        <Resolver
          successComponent={ServiceEditorPage}
          loadingComponent={PageLoading}
          resolve={this.resolve}
          redirectOnError="/opportunities"
        />
      </>
    );
  }
}
ServiceEditorPageResolver.propTypes = {
  location: PropTypes.object,
  serviceId: PropTypes.string,
  service: PropTypes.object,
  loadServiceOptions: PropTypes.func.isRequired,
  resetServiceOptions: PropTypes.func.isRequired,
};

ServiceEditorPageResolver.defaultProps = {
  location: undefined,
  serviceId: undefined,
  service: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const { locationId, serviceId } = ownProps.match.params;

  const location = serviceId
    ? locationByServiceIdSelector(state.quote.quote, ownProps.match.params.serviceId)
    : state.quote.quote.locations[locationId];

  const service = serviceId ? serviceByIdSelector(state.quote.quote, serviceId) : undefined;

  return {
    location,
    serviceId,
    service,
  };
};

const mapDispatchToProps = { loadServiceOptions, resetServiceOptions };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ServiceEditorPageResolver),
);
