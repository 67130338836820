import { map } from 'lodash-es';

export const transformLoadUsers = ({ userGridView, ...response }) => ({
  ...response,
  users: map(
    userGridView,
    ({ companyId, companyName, email, firstName, lastName, phoneNumber, userId, userRoleName }) => ({
      userId,
      email,
      role: userRoleName,
      firstName,
      lastName,
      phoneNumber,
      company: {
        companyId,
        companyName,
      },
    }),
  ),
});

export const transformLoadUser = ({ userRoleName, ...userResponse }) => ({ ...userResponse, role: userRoleName });
