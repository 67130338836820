import React from 'react';
import PropTypes from 'prop-types';
import { primaryItems } from '../../navigation/navigationItems';
import { RoleGuard } from '../../../account/components';

const NavigationItems = ({ ItemComponent, ...props }) => (
  <>
    {primaryItems.map(item =>
      item.roles.length ? (
        <RoleGuard roles={item.roles} key={item.to}>
          <ItemComponent item={item} {...props} />
        </RoleGuard>
      ) : (
        <ItemComponent item={item} key={item.to} {...props} />
      ),
    )}
  </>
);

NavigationItems.propTypes = {
  ItemComponent: PropTypes.any.isRequired,
};

export default NavigationItems;
