import React from 'react';
import { Switch } from 'react-router';
import { PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER } from '../../account/constants';
import { AuthRoute, RoleRoute } from '../../account/components';
import {
  ServiceProviderPage,
  LocationEditorPageResolver,
  LocationEditorPage,
  ServiceEditorPageResolver,
  SummaryPage,
} from './pages';

const ExistingQuoteRouter = () => (
  <Switch>
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/:quoteId/choose-provider"
      component={ServiceProviderPage}
    />
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/:quoteId/locations/new"
      component={LocationEditorPage}
    />
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/:quoteId/locations/:locationId"
      component={LocationEditorPageResolver}
    />
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/:quoteId/locations/:locationId/services"
      component={ServiceEditorPageResolver}
    />
    <RoleRoute
      roles={[PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER]}
      exact
      path="/quote/:quoteId/services/:serviceId"
      component={ServiceEditorPageResolver}
    />
    <AuthRoute exact path="/quote/:quoteId/summary" component={SummaryPage} />
  </Switch>
);

export default ExistingQuoteRouter;
