import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorMapper, media } from '../../../utils/styles';
import { BareButtonStyle } from './Button';
import { Icon } from '..';

export const DropdownMenuItem = styled.li`
  padding: 12px 10px;
  border-bottom: 1px solid ${props => props.theme.grayLight};
  text-align: center;
  color: ${colorMapper};
  cursor: pointer;

  &:hover {
    background-color: ${transparentize(0.98, '#000')};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const DropdownMenuItems = styled.ul`
  position: absolute;
  left: -80px;
  z-index: 1;
  list-style: none;
  width: 180px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 24px ${transparentize(0.85, '#000')};

  ${media.custom(1150)`
    width: 125px;
  `}

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 86px
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${props => props.theme.grayLight};
    border-left: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: 87px
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;;
    border-left: 7px solid transparent;
  }
`;

export const DropdownMenuToggleIcon = styled(Icon).attrs({ icon: 'more' })`
  width: 16px;
`;

export const DropdownMenuToggle = styled.button`
  ${BareButtonStyle};
  width: 26px;
  height: 18px;
  background-color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s ease-out;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.grayLight};
  }

  &:focus {
    box-shadow: inset 0 0 4px ${transparentize(0.9, '#000')};
  }
`;

export const DropdownMenu = styled.div`
  position: relative;
`;
