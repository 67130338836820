import { isRequired, isPhone, isEmail, isPositiveNumber } from '../../../../utils/services/validator';

const validate = values => {
  const errors = {};

  if (!values.followUpDate) {
    errors.followUpDate = isRequired(values.followUpDate);
  }

  if (!values.contactName) {
    errors.contactName = isRequired(values.contactName);
  }

  if (!values.customerEmail) {
    errors.customerEmail = isRequired(values.customerEmail);
  } else {
    errors.customerEmail = isEmail(values.customerEmail);
  }

  if (values.currentSpending) {
    errors.currentSpending = isPositiveNumber(values.currentSpending);
  }

  if (!values.customerPhone) {
    errors.customerPhone = isRequired(values.customerPhone);
  } else {
    errors.customerPhone = isPhone(values.customerPhone);
  }
  return errors;
};

export default validate;
