import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, Field } from 'redux-form';
import { FormGroup, RadioGroupFormLabel, RadioGroupContainer } from './styled';
import Radio from './Radio';

class RadioGroup extends PureComponent {
  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      input: { name },
      items,
      margin,
      label,
    } = this.props;

    return (
      <FormGroup margin={margin}>
        {!!label && <RadioGroupFormLabel>{label}</RadioGroupFormLabel>}
        <RadioGroupContainer>
          {items.map((item, index) => (
            <Field
              key={index}
              name={name}
              component={Radio}
              type="radio"
              label={item.label}
              value={item.value}
              onChange={this.onChange}
            />
          ))}
        </RadioGroupContainer>
      </FormGroup>
    );
  }
}

RadioGroup.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.any,
  margin: PropTypes.string,
};

RadioGroup.defaultProps = {
  label: undefined,
  margin: undefined,
};

export default RadioGroup;
