import { filter } from 'lodash-es';
import moment from 'moment-timezone';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const decimal = (number, precision = 2) => Number(number).toFixed(precision);
export const enumeration = (...items) => filter(items).join(', ');
export const currency = amount => currencyFormatter.format(amount);

export const localDate = date =>
  moment
    .utc(date)
    .local()
    .format('L');

export const phoneNumber = phoneNumber => {
  const match = phoneNumber && phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ` ${match[1]}-${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};
