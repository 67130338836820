import { PARTNER_SUPER_ADMIN, PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER } from '../../account/constants';

const navigationItems = {
  opportunities: {
    to: '/opportunities',
    icon: 'opportunitiesCircle',
    roles: [],
    caption: 'Opportunities',
  },
  quote: {
    to: '/quote',
    icon: 'quoteCircle',
    roles: [PARTNER_ADMIN, PARTNER_MANAGER, PARTNER_USER],
    caption: 'Quote',
  },
  users: {
    to: '/users',
    icon: 'usersCircle',
    roles: [PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN],
    caption: 'Users',
  },
  market: {
    to: '/market/market-availability',
    icon: 'marketCircle',
    roles: [PARTNER_SUPER_ADMIN, PARTNER_MANAGER, PARTNER_ADMIN],
    caption: 'Market',
  },
  logout: {
    to: '/account/logout',
    caption: 'Logout',
  },
};

const primaryItems = [
  navigationItems.opportunities,
  navigationItems.quote,
  navigationItems.users,
  navigationItems.market,
];
const defaultItem = navigationItems.opportunities;

export default navigationItems;
export { primaryItems, defaultItem };
