import { combineReducers } from 'redux';
import { reducer as userReducer } from './user';
import { reducer as usersReducer } from './users';
import { reducer as companiesReducer } from './companies';

export { saveUser, loadUser, resetUser } from './user';
export { loadUsers, deleteUser, resetUsers } from './users';
export { loadCompanies, resetCompanies } from './companies';

export const reducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  companies: companiesReducer,
});
