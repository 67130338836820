import update from 'immutability-helper';
import { createUser, updateUser, loadUser as doLoadUser } from '../services/user';

// Actions
const START_SAVE = 'users/user/START_SAVE';
const COMPLETE_SAVE = 'users/user/COMPLETE_SAVE';
const FAIL_SAVE = 'users/user/FAIL_SAVE';
const START_LOAD = 'users/user/START_LOAD';
const COMPLETE_LOAD = 'users/user/COMPLETE_LOAD';
const FAIL_LOAD = 'users/user/FAIL_LOAD';
const RESET = 'users/user/RESET';

// Initial state
const initialState = {
  isLoading: false,
  isLoadFailed: false,
  isSaving: false,
  isSaveFailed: false,
  user: undefined,
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          isSaveFailed: true,
        },
      });

    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          user: action.user,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          isLoadFailed: true,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startSave = () => ({
  type: START_SAVE,
});

const completeSave = () => ({
  type: COMPLETE_SAVE,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = user => ({
  type: COMPLETE_LOAD,
  user,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const saveUser = user => dispatch => {
  dispatch(startSave());
  const saveUserPromise = user.userId ? updateUser(user) : createUser(user);
  saveUserPromise.then(() => dispatch(completeSave())).catch(() => dispatch(failSave()));

  return saveUserPromise;
};

export const loadUser = userId => dispatch => {
  dispatch(startLoad());
  const loadUserPromise = doLoadUser(userId);
  loadUserPromise.then(user => dispatch(completeLoad(user))).catch(() => dispatch(failLoad()));

  return loadUserPromise;
};

export const resetUser = () => ({
  type: RESET,
});
