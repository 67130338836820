import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';
import { formValueSelector, submit } from 'redux-form';
import {
  Panel,
  Button,
  ButtonLink,
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
} from '../../../core/components/styled';
import { PageHeader, PageTitle, PageActions } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { createUrl } from '../../../utils/services/queryParams';
import { addLocation, saveQuote, resetQuote, locationsSelector } from '../../ducks';
import { ServiceProviderForm } from '../forms';
import { NonMobile } from '../../../common/components/Responsive';
import isFalsy from '../../../utils/services/isFalsy';

class ServiceProviderPage extends PureComponent {
  componentDidMount() {
    const { match, resetQuote } = this.props;
    const { quoteId } = match.params;

    if (!quoteId) {
      resetQuote();
    }

    // Skipping service provider page RSP-1355
    this.onFormSubmit({ serviceProvider: 0 });
  }

  onFormSubmit = async ({ serviceProvider }) => {
    const { match, push } = this.props;
    const { quoteId } = match.params;
    const locationEditorUrl = quoteId ? `/quote/${quoteId}/locations/new` : `/quote/locations/new`;
    push(createUrl(locationEditorUrl, undefined, { serviceProvider }));
  };

  submitForm = () => {
    const { submit } = this.props;
    submit('serviceProvider');
  };

  render() {
    const { match, locations, serviceProvider, isSaving } = this.props;
    const { quoteId } = match.params;

    return (
      <>
        <DocumentTitle>Choose service provider</DocumentTitle>
        <PageHeader>
          <NonMobile>
            <PageTitle>Select provider</PageTitle>
          </NonMobile>
          <PageActions>
            {!!locations.length && (
              <ButtonLink
                line
                color="primary"
                margin="no small no no"
                marginTablet="no xSmall no no"
                marginPhone="no xxSmall no no"
                disabled={isSaving}
                to={`/quote/${quoteId}/locations/${locations[0].id}/services`}
              >
                Cancel
              </ButtonLink>
            )}

            <Button
              color="primary"
              disabled={isFalsy(serviceProvider, { allowZero: true }) || isSaving}
              onClick={this.submitForm}
            >
              Next
            </Button>
          </PageActions>
        </PageHeader>

        <Panel isLoading={isSaving}>
          <PanelSection padding="large no xLarge" paddingTablet="no" align="center">
            <PanelSectionHeader margin="no no medium" marginTablet="no no small" marginPhone="no no xxSmall">
              <PanelSectionTitle>Select current service provider</PanelSectionTitle>
            </PanelSectionHeader>
            <ServiceProviderForm onSubmit={this.onFormSubmit} />
          </PanelSection>
        </Panel>
      </>
    );
  }
}

ServiceProviderPage.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  serviceProvider: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  match: RouterPropTypes.match.isRequired,
  resetQuote: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

ServiceProviderPage.defaultProps = {
  serviceProvider: undefined,
};

const formSelector = formValueSelector('serviceProvider');
const locationsFilter = { mustBeQualified: true, mustHaveAddress: true };

const mapStateToProps = state => ({
  serviceProvider: formSelector(state, 'serviceProvider'),
  locations: locationsSelector(state.quote.quote, locationsFilter),
  isSaving: state.quote.quote.isSaving,
});

const mapDispatchToProps = {
  addLocation,
  saveQuote,
  resetQuote,
  submit,
  push,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ServiceProviderPage),
);
