import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal as ModalContainer, ModalDialog } from './styled';

class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this.containerElement = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.containerElement);
  }

  componentWillUnmount() {
    document.body.removeChild(this.containerElement);
  }

  render() {
    const { size, verticalSize, padding, children, flex, ...props } = this.props;
    return ReactDOM.createPortal(
      <ModalContainer {...props}>
        <ModalDialog size={size} verticalSize={verticalSize} padding={padding} flex={flex}>
          {children}
        </ModalDialog>
      </ModalContainer>,
      this.containerElement,
    );
  }
}

Modal.propTypes = {
  size: PropTypes.string,
  verticalSize: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
};

Modal.defaultProps = {
  size: undefined,
  verticalSize: undefined,
  padding: undefined,
  flex: false,
};

export default Modal;
