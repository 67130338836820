import http from '../../core/services/http';

export const requestPasswordReset = email =>
  http.post('partnerPortal/forgotPassword', {
    callbackUrl: 'account/reset-password',
    email,
  });

export const resetPassword = (code, email, password) =>
  http.post('partnerPortal/resetPassword', {
    code,
    email,
    password,
  });

export const changePassword = (oldPassword, newPassword) =>
  http.post('partnerPortal/changepassword', {
    oldPassword,
    newPassword,
  });

export const acceptInvitation = (code, email, password) =>
  http.post(`partnerportal/register`, { code, email, password });

export const loadAccountProfile = () => http.get(`partnerPortal/user`).then(response => response.data);

export const saveAccountProfile = accountProfile => http.put('partnerPortal/user', { ...accountProfile });

export const sendPhoneVerificationCode = ({ countryCode, email, phoneNumber, sendVoiceMessage }) =>
  http.post(
    `/partnerportal/phone/confirmation
  `,
    { countryCode, email, phoneNumber, sendVoiceMessage },
  );

export const validatePhoneNumber = ({ requestToken, userId, code }) =>
  http.post(
    `/partnerportal/phone/confirm
  `,
    { requestToken, userId, code },
  );

export const checkAvailablePhoneNumber = ({ phoneNumber }) =>
  http.post(
    `/partnerportal/user/can-use-phone
  `,
    { phoneNumber },
  );
