import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { FRONT_LOAD, CART } from '../../constants';
import { sizeMapper } from '../../../utils/styles';
import frontLoadContainerImg from '../../assets/img/frontLoadContainer.png';
import cartContainerImg from '../../assets/img/cartContainer.png';

export const ServiceTypeOptionImage = styled.div`
  width: 100%;
  height: 100px;
  background: #02978b center center no-repeat;
  border-radius: 4px 4px 0 0;
`;

export const ServiceTypeOptionLabel = styled.div`
  padding: 12px 0 12px;
  border-radius: 0 0 4px 4px;
  border: solid ${transparentize(0.9, '#000')};
  border-width: 0 1px 1px;
  text-align: center;
`;

export const ServiceTypeOption = styled.li`
  position: relative;
  width: 160px;
  margin-right: 10px;
  border-radius: 4px;
  transform: none;
  transition: transform 0.2s linear, box-shadow 0.3s ease-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 24px ${transparentize(0.85, '#000')};
  }

  &:active {
    transform: scale(0.98, 0.98);
  }

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.isSelected &&
    css`
      &:after {
        position: absolute;
        top: 8px;
        right: 8px;
        content: '';
        display: inline-block;
        width: 16px;
        height: 12px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTUuMDkgOS40OTNMMS4yNzQgNS43MyAwIDYuOTg1IDUuMDkgMTIgMTYgMS4yNTQgMTQuNzI3IDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=')
          center center no-repeat;
      }
    `};

  ${props =>
    props.type === FRONT_LOAD &&
    css`
      ${ServiceTypeOptionImage} {
        background-image: url(${frontLoadContainerImg});
        background-size: 132px auto;
      }
    `};

  ${props =>
    props.type === CART &&
    css`
      ${ServiceTypeOptionImage} {
        background-image: url(${cartContainerImg});
        background-size: 128px auto;
      }
      }
    `};
`;

export const ServiceTypeSelect = styled.ul`
  display: flex;
  list-style: none;
  margin: ${props => sizeMapper(props.margin, 'no')};
`;
