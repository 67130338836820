import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, fieldInputPropTypes } from 'redux-form';
import { createSelector } from 'reselect';
import { map, sortBy, identity } from 'lodash-es';
import { Select } from '../../core/components';
import { isRequired } from '../../utils/services/validator';
import { toNumberIfNotEmptyString } from '../../utils/services/normalizer';
import { CONTAINERS_BY_SERVICE_TYPE } from '../constants';

const containersSizeOptionsSelector = createSelector(
  (serviceType, acceptedContainerSizes) => {
    const containers = CONTAINERS_BY_SERVICE_TYPE[serviceType];
    const containerSizes = acceptedContainerSizes || sortBy(map(containers, 'size'));

    return map(containerSizes, containerSize => ({
      label: containers[containerSize].name,
      value: containerSize,
    }));
  },
  identity,
);

class ContainerSizeSelect extends PureComponent {
  state = {};

  static getDerivedStateFromProps(props) {
    const {
      acceptedContainerSizes,
      input: { value, onChange },
    } = props;

    if (
      acceptedContainerSizes &&
      acceptedContainerSizes.length &&
      acceptedContainerSizes.indexOf(value) === -1 &&
      value
    ) {
      onChange('');
    }

    return null;
  }

  onChange = (event, value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      input: { name },
      serviceType,
      acceptedContainerSizes,
    } = this.props;

    const containerSizeOptions = containersSizeOptionsSelector(serviceType, acceptedContainerSizes);

    return (
      <Field
        name={name}
        component={Select}
        label="Container size"
        options={containerSizeOptions}
        normalize={toNumberIfNotEmptyString}
        validate={[isRequired]}
        onChange={this.onChange}
      >
        <option value="" />
      </Field>
    );
  }
}

ContainerSizeSelect.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  serviceType: PropTypes.string,
  acceptedContainerSizes: PropTypes.arrayOf(PropTypes.number),
};

ContainerSizeSelect.defaultProps = {
  serviceType: undefined,
  acceptedContainerSizes: undefined,
};

export default ContainerSizeSelect;
