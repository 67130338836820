import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Resolver } from '../../../core/components';
import { PageLoading } from '../../../common/components/styled';
import { DocumentTitle } from '../../../common/components';
import { QUOTED } from '../../../quote/constants';
import { loadOpportunity } from '../../ducks';
import QuotedOpportunityDetailsPage from './QuotedOpportunityDetailsPage';

class QuotedOpportunityDetailsPageResolver extends PureComponent {
  resolve = () => {
    const { match, loadOpportunity } = this.props;
    const { quoteId } = match.params;
    return loadOpportunity(quoteId, QUOTED);
  };

  render() {
    return (
      <>
        <DocumentTitle>Service details</DocumentTitle>
        <Resolver
          successComponent={QuotedOpportunityDetailsPage}
          loadingComponent={PageLoading}
          resolve={this.resolve}
        />
      </>
    );
  }
}

QuotedOpportunityDetailsPageResolver.propTypes = {
  loadOpportunity: PropTypes.func.isRequired,
  match: RouterPropTypes.match.isRequired,
};

const mapDispatchToProps = { loadOpportunity };

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(QuotedOpportunityDetailsPageResolver),
);
