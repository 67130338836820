import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenu as DropdownMenuContainer,
  DropdownMenuToggle,
  DropdownMenuToggleIcon,
  DropdownMenuItems,
  DropdownMenuItem,
} from './styled';

class DropdownMenu extends PureComponent {
  state = { isOpen: false };

  componentWillUnmount() {
    document.removeEventListener('click', this.close);
  }

  onItemClick = (event, item) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    item.onClick();
    this.close();
  };

  open = () => {
    document.addEventListener('click', this.close);
    this.setState({ isOpen: true });
  };

  close = () => {
    document.removeEventListener('click', this.close);
    this.setState({ isOpen: false });
  };

  toggle = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    const { isOpen } = this.state;

    if (isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  render() {
    const { isOpen } = this.state;
    const { items } = this.props;

    return (
      <DropdownMenuContainer>
        <DropdownMenuToggle onClick={this.toggle}>
          <DropdownMenuToggleIcon />
        </DropdownMenuToggle>

        {isOpen && (
          <DropdownMenuItems>
            {items.map((item, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <DropdownMenuItem key={index} color={item.color} onClick={event => this.onItemClick(event, item)}>
                {item.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuItems>
        )}
      </DropdownMenuContainer>
    );
  }
}

DropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default DropdownMenu;
