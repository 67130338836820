import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles';
import {
  Confirmation as ConfirmationContainer,
  ConfirmationDialog,
  ConfirmationTitle,
  ConfirmationMessage,
  ButtonSet,
  Button,
} from './styled';

class Confirmation extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.keyboardHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboardHandler, false);
  }

  keyboardHandler = event => {
    event.preventDefault();
    if (event.keyCode === 27) {
      this.cancel();
      return;
    }

    if (event.keyCode === 13) {
      this.accept();
    }
  };

  accept = value => {
    const { resolve } = this.props;
    resolve(value);
  };

  cancel = value => {
    const { reject } = this.props;
    reject(value);
  };

  render() {
    const { title, message } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <ConfirmationContainer>
          <ConfirmationDialog>
            <ConfirmationTitle>{title}</ConfirmationTitle>
            <ConfirmationMessage>{message}</ConfirmationMessage>
            <ButtonSet align="right">
              <Button line color="primary" margin="no small no no" onClick={this.cancel}>
                No
              </Button>

              <Button color="primary" onClick={this.accept}>
                Yes
              </Button>
            </ButtonSet>
          </ConfirmationDialog>
        </ConfirmationContainer>
      </ThemeProvider>
    );
  }
}

Confirmation.propTypes = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};

Confirmation.defaultProps = {
  message: undefined,
};

export default Confirmation;
