import React from 'react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { range } from 'lodash-es';
import { withRouter } from 'react-router';
import { getQueryParams, createUrl } from '../../utils/services/queryParams';
import { Pagination as PaginationContainer, PaginationItem, PaginationControl } from './styled';
import calculatePaginationBoundaries from '../../utils/services/calculatePaginationBoundaries';

const Pagination = ({ totalResults, limitPerPage, maxPaginationItems, location }) => {
  const url = location.pathname;
  const urlParams = location.search;
  const currentPage = Number(getQueryParams(urlParams).page) || 1;

  const { firstPage, lastPage } = calculatePaginationBoundaries(
    totalResults,
    limitPerPage,
    maxPaginationItems,
    currentPage,
  );

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  return (
    <PaginationContainer withoutFirstControl={isFirstPage} withoutLastControl={isLastPage}>
      {isFirstPage ? null : (
        <PaginationControl exact to={createUrl(url, urlParams, { page: currentPage - 1 })}>
          Previous
        </PaginationControl>
      )}

      {range(firstPage, lastPage + 1).map(page => (
        <PaginationItem key={page} exact to={createUrl(url, urlParams, { page })} isActive={() => currentPage === page}>
          {page}
        </PaginationItem>
      ))}

      {isLastPage ? null : (
        <PaginationControl exact to={createUrl(url, urlParams, { page: currentPage + 1 })}>
          Next
        </PaginationControl>
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  totalResults: PropTypes.number.isRequired,
  limitPerPage: PropTypes.number.isRequired,
  maxPaginationItems: PropTypes.number.isRequired,
  location: RouterPropTypes.location.isRequired,
};

export default withRouter(Pagination);
