import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isRequired, isPositiveNumber, isInteger, isGreaterThanField } from '../../../../utils/services/validator';
import { toNumberIfNotEmptyString, toDecimalNoRound } from '../../../../utils/services/normalizer';
import { Input, Checkbox } from '../../../../core/components';
import { FRONT_LOAD_CONTAINERS } from '../../../../common/constants';
import { WasteTypeSelect, ContainerSizeSelect, RecurringFrequencySelect } from '../../../../common/components';
import { currency } from '../../../../utils/services/formatter';

const isValidPrice = isGreaterThanField('service.FrontLoad.price.pickupExpectedCost', currency);

const FrontLoadService = ({
  serviceType,
  wasteTypes,
  containerSizes,
  recurringFrequencies,
  containerSize,
  hasIncumbentRebateProgram,
  hasRecognitionProgram,
  initialValues,
  change,
}) => {
  const [showIncumbentRebateMonthly, setShowIncumbentRebateMonthly] = useState(false);
  const [showAdjustedPrice, setShowAdjustedPrice] = useState(true);

  useEffect(() => {
    if (
      initialValues &&
      initialValues.service &&
      initialValues.service.FrontLoad &&
      initialValues.service.FrontLoad.incumbentRebateProgram
    ) {
      setShowIncumbentRebateMonthly(true);
    }
  }, []);

  useEffect(() => {
    if (initialValues && initialValues.service && initialValues.service.FrontLoad) {
      setShowIncumbentRebateMonthly(Boolean(initialValues.service.FrontLoad.incumbentRebateProgram));
      if (initialValues.service.FrontLoad.id) {
        setShowAdjustedPrice(true);
      }
    }
  }, [initialValues]);

  const hideAdjustedPrice = () => {
    if (showAdjustedPrice && initialValues.service.FrontLoad) {
      setShowAdjustedPrice(false);
      change('service.FrontLoad.price.pickup', null);
    }
  };

  const onContainerSizeChange = (event, containerSize) => {
    if (!containerSize || !FRONT_LOAD_CONTAINERS[containerSize].canHaveCasters) {
      change('service.FrontLoad.hasCasters', false);
    }
    hideAdjustedPrice();
  };

  const onChangeRebateProgram = () => {
    setShowIncumbentRebateMonthly(!showIncumbentRebateMonthly);
    hideAdjustedPrice();
  };

  return (
    <>
      {hasRecognitionProgram && (
        <Field
          name="service.FrontLoad.recognizedProgram"
          component={Checkbox}
          label="Recognized Program"
          block
          margin="small no"
          onChange={hideAdjustedPrice}
        />
      )}
      {hasIncumbentRebateProgram && (
        <Field
          name="service.FrontLoad.incumbentRebateProgram"
          component={Checkbox}
          label="Rebate Program"
          block
          margin="small no"
          onChange={onChangeRebateProgram}
        />
      )}
      {hasIncumbentRebateProgram && showIncumbentRebateMonthly && (
        <Field
          name="service.FrontLoad.incumbentRebateMonthly"
          component={Input}
          label="Incumbent price"
          type="number"
          normalize={toNumberIfNotEmptyString}
          validate={[isPositiveNumber, isInteger]}
          min={0}
          onChange={hideAdjustedPrice}
        />
      )}
      <Field
        name="service.FrontLoad.numberOfContainers"
        component={Input}
        label="Number of containers"
        type="number"
        normalize={toNumberIfNotEmptyString}
        validate={[isRequired, isPositiveNumber, isInteger]}
        min={0}
        onChange={hideAdjustedPrice}
      />
      <Field
        name="service.FrontLoad.wasteType"
        component={WasteTypeSelect}
        serviceType={serviceType}
        acceptedWasteTypes={wasteTypes}
        validate={isRequired}
        onChange={hideAdjustedPrice}
      />
      <Field
        name="service.FrontLoad.containerSize"
        component={ContainerSizeSelect}
        serviceType={serviceType}
        acceptedContainerSizes={containerSizes}
        validate={isRequired}
        onChange={onContainerSizeChange}
      />
      {initialValues.service.FrontLoad && initialValues.service.FrontLoad.price && showAdjustedPrice && (
        <Field
          name="service.FrontLoad.price.pickup"
          label="Adjusted Pickup Price"
          component={Input}
          type="number"
          normalize={toDecimalNoRound}
          validate={[isValidPrice]}
        />
      )}
      <Field
        name="service.FrontLoad.recurringFrequency"
        component={RecurringFrequencySelect}
        serviceType={serviceType}
        acceptedRecurringFrequencies={recurringFrequencies}
        validate={isRequired}
        onChange={hideAdjustedPrice}
      />
      <Field name="service.FrontLoad.note" component={Input} label="Notes" type="text" />
      <Field
        name="service.FrontLoad.hasLockbar"
        component={Checkbox}
        label="Lockbar"
        block
        margin="small no"
        onChange={hideAdjustedPrice}
      />
      {containerSize && FRONT_LOAD_CONTAINERS[containerSize] && FRONT_LOAD_CONTAINERS[containerSize].canHaveCasters && (
        <Field name="service.FrontLoad.hasCasters" component={Checkbox} label="Casters" block />
      )}
    </>
  );
};

FrontLoadService.propTypes = {
  serviceType: PropTypes.string,
  wasteTypes: PropTypes.array,
  containerSizes: PropTypes.array,
  recurringFrequencies: PropTypes.array,
  containerSize: PropTypes.number,
  change: PropTypes.func.isRequired,
  hasIncumbentRebateProgram: PropTypes.bool,
  hasRecognitionProgram: PropTypes.bool,
  initialValues: PropTypes.object,
};

FrontLoadService.defaultProps = {
  serviceType: undefined,
  wasteTypes: undefined,
  containerSizes: undefined,
  recurringFrequencies: undefined,
  containerSize: undefined,
  hasIncumbentRebateProgram: undefined,
  hasRecognitionProgram: undefined,
  initialValues: undefined,
};

export default FrontLoadService;
