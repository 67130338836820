import update from 'immutability-helper';
import { loadAddresses as doLoadAddresses } from '../services/loadAddresses';

// Actions
const START_LOAD = 'common/addresses/START_LOAD';
const COMPLETE_LOAD = 'common/addresses/COMPLETE_LOAD';
const RESET = 'common/addresses/RESET';

// Initial state
const initialState = {
  isLoading: false,
  addresses: [],
};

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, { $merge: { isLoading: true } });

    case COMPLETE_LOAD:
      return update(state, { $merge: { isLoading: false, addresses: action.addresses } });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = addresses => ({
  type: COMPLETE_LOAD,
  addresses,
});

export const loadAddresses = searchTerm => async dispatch => {
  dispatch(startLoad());
  const addresses = await doLoadAddresses(searchTerm);
  dispatch(completeLoad(addresses));
};

export const resetAddresses = () => ({
  type: RESET,
});
