import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
  ButtonSet,
  Button,
} from '../../../core/components/styled';
import { Input } from '../../../core/components';
import { FRONT_LOAD, CART } from '../../../common/constants';
import { ServiceTypeSelect } from '../../../common/components';
import { FrontLoadService, CartService } from './serviceEditorFormSections';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { getUser } from '../../../account/services/auth';

const ServiceEditorForm = ({
  isNew,
  serviceType,
  serviceTypes,
  serviceOptions,
  containerSize,
  change,
  handleSubmit,
  initialValues,
}) => {
  const { wasteTypes, containerSizes, recurringFrequencies } = serviceOptions || {};
  const user = getUser();

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection margin="no no medium">
        <PanelSectionHeader>
          <PanelSectionTitle>Location details</PanelSectionTitle>
        </PanelSectionHeader>

        <Field name="location.businessName" component={Input} label="Business name" readOnly />
        <Field name="location.address.line1" component={Input} label="Address" readOnly />
      </PanelSection>

      <PanelSection>
        <PanelSectionHeader>
          <PanelSectionTitle>Service details</PanelSectionTitle>
        </PanelSectionHeader>

        <Field
          name="service.serviceType"
          component={ServiceTypeSelect}
          acceptedServiceTypes={serviceTypes}
          margin="no no medium"
        />

        {serviceType === FRONT_LOAD && (
          <FrontLoadService
            serviceType={serviceType}
            wasteTypes={wasteTypes}
            containerSizes={containerSizes}
            recurringFrequencies={recurringFrequencies}
            containerSize={containerSize}
            change={change}
            hasIncumbentRebateProgram={user.hasIncumbentRebateProgram}
            hasRecognitionProgram={user.hasRecognitionProgram}
            initialValues={initialValues}
          />
        )}

        {serviceType === CART && (
          <CartService
            serviceType={serviceType}
            wasteTypes={wasteTypes}
            containerSizes={containerSizes}
            recurringFrequencies={recurringFrequencies}
            hasIncumbentRebateProgram={user.hasIncumbentRebateProgram}
            hasRecognitionProgram={user.hasRecognitionProgram}
            initialValues={initialValues}
            change={change}
          />
        )}

        <ButtonSet>
          <Button color="primary">{isNew ? 'Add to quote' : 'Update service'}</Button>
        </ButtonSet>
      </PanelSection>
    </form>
  );
};

ServiceEditorForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
  serviceType: PropTypes.string,
  serviceTypes: PropTypes.arrayOf(PropTypes.string),
  serviceOptions: PropTypes.object,
  containerSize: PropTypes.number,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

ServiceEditorForm.defaultProps = {
  serviceType: undefined,
  serviceTypes: undefined,
  serviceOptions: undefined,
  containerSize: undefined,
  initialValues: undefined,
};

export default reduxForm({
  form: 'serviceEditor',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(ServiceEditorForm);
